import articleApi from "@/api/ArticleApi"
import { getEssay } from "@/api/EowayApi";
import { boardMap } from "@/composables/functions";
import { DEV_MODE } from "@/main";
import Customer from "@/pages/customer/index.vue"
import Essay from "@/pages/essay/index.vue"

export const customerRedirects = [
  {
    path: "/customer",
    redirect: "/customer/notice",
  },
  {
    path: "/essay",
    redirect: "/customer/essay"
  },
  {
    path: "/customer/essay/:essayId",
    redirect: to => {
      return `/essay/${to.params.essayId}`;
    }
  },
]

export const customerRoutes = [
  {
    name: "customer-board-articleId",
    path: "/customer/:board/:articleId",
    component: Customer,
    beforeEnter: async (to, from, next) => {      
      const {board, articleId} = to.params;
      const {boardId} = boardMap[board];
      
      try {
        const response = await articleApi().get(boardId, articleId, {});
        
        if (response) next();
        else next(`/customer/${board}`);
      } catch (e) {
        DEV_MODE && console.log('customer-board-articleId beforeEnter error:', e);
        next(`/customer/${board}`);
      }
    },
    props: true,
    meta: {
      layout: "customer"
    }
  },
  {
    name: "customer-board",
    path: "/customer/:board",
    component: Customer,
    props: (route) => ({ ...route.params }),
    meta: {
      layout: "customer"
    }
  },
  {
    name: "essay-id",
    path: "/essay/:essayId",
    component: Essay,
    props: true,
    meta: {
      layout: "essay"
    },
    beforeEnter: async (to, from, next) => {
      const {essayId} = to.params;
      
      try {
        const response = await getEssay({essayId});

        if (response) next();
        else next(`/customer/essay`);
      } catch (e) {
        DEV_MODE && console.log('essay-id beforeEnter error:', e);
        next(`/customer/essay`);
      }
    },
  },
]
