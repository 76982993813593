import axios from "axios";
import {useApiClient}  from "@/api/ApiClient"
import { generateHeaders } from "./headers";
const endpoint = import.meta.env.VITE_APP_GW_URL
const systemId = import.meta.env.VITE_APP_SYSTEMID

const { get, get2, put, post, del} = useApiClient();

/* user group list */
export const getGrouplist = async () => {
  let path = `/users/userGroups`
  const { data } = await get(path, {})
  return data
}

/* 저장목록 list */
export const getGroupUserlist = async (params) => {
  let path = `/users/groupGuestUsers`
  // params에서 fiveEles를 배열로 변환
  if (params.fiveEles) {
    // fiveEles가 문자열인 경우 배열로 변환
    const fiveElesArray = Array.isArray(params.fiveEles) ? params.fiveEles : params.fiveEles.split(',');

    // 쿼리 문자열 생성
    const queryString = fiveElesArray.map(ele => `fiveEles=${encodeURIComponent(ele)}`).join('&');
    path += `?${queryString}`;
  }
  const { data } = await get2(path, params)
  return data
}

/* 저장상세뷰 */
export const getDeatil = async (id) => {
  let path = `/users/groupGuestUsers/${id}`
  const { data } = await get2(path, {})
  return data
}

/** 사주 삭제 */
const sajuDelete = async (id) => {
  const path = `${endpoint}/users/groupGuestUsers`
  const headers = generateHeaders();
  const params = {
    guestUserIds: id,
    systemId
  };
  const response = await axios.delete(path, { params, headers })
  return response.data
}
/** 최근 사주 */
const getList = async ({params}) => {
  const path = `${endpoint}/users/groupGuestUsers`
  const headers = generateHeaders();
  params = {
    ...params,
    systemId,
  }
  const response = await axios.get(path, { params, headers })
  return response.data
}

/* 수정 */
export const updateGroup = async (params) => {
  let path = `/users/groupGuestUsers/`
  const { data } = await put(path, params)
  return data
}

export {sajuDelete, getList}

/* 등록 */
export const saveGroup = async (params) => {
  let path = `/users/userGroups`
  const { data } = await post(path, params)
  return data
}

/* 수정 */
export const modifyGroup = async (params) => {
  let path = `/users/userGroups`
  const { data } = await put(path, params)
  return data
}

/* 삭제 */
export const delGroup = async (params) => {
  let path = `/users/userGroups`
  const { data } = await del(path, params)
  return data
}

/* 메모 등록 */
export const saveMemo = async (params) => {
  let path = `/users/guestUserMemos`
  const { data } = await post(path, params)
  return data
}

/* 메모 목록 */
export const getMemoList = async (params) => {
  let path = `/users/guestUserMemos`
  const { data } = await get2(path, params)
  return data
}

/* 메모 삭제 */
export const delMemo = async (params) => {
  let path = `/users/guestUserMemos`
  const { data } = await del(path, params)
  return data
}

/* 메모 수정 */
export const modMemo = async (params) => {
  let path = `/users/guestUserMemos`
  const { data } = await put(path, params)
  return data
}