import {customerRoutes, customerRedirects} from "./customer-routes"
import manseRoutes from "./manse-routes"
import MansePage from "@/pages/manse/index.vue"

export const redirects = [
  ...customerRedirects,
]
export const routes = [
  {
    path: '/manse/:id/:pgmode',
    component: MansePage,
    meta: {
      layout: "manse"
    }
  },
  {
    path: '/manse/:id',
    component: MansePage,
    meta: {
      layout: "manse"
    }
  },
  {
    path: '/manse',
    component: MansePage,
    meta: {
      layout: "manse"
    }
  },
  ...manseRoutes,
  ...customerRoutes,
]
