<script setup>
import fiveMixin from '@/composables/fiveMixin';
import useFunctions from '@/composables/useFunctions';
import ManseCardItem from "@/pages/manse/components/ManseCardItem.vue";

const { ...five } = fiveMixin();
const { ...useFun } = useFunctions();
const props = defineProps({
  prDataAll: {
    type: Object,
    default: null,
  },
  prSortMode:{
    type: String,
    default: 'desc',
  },
  prLangMode:{
    type: String,
    default: 'CHNKOR',
  }
});

onMounted(() => {
  dataSet(props.prDataAll); // 초기 데이터 설정
});

const galleryTitles = ref(['합', '충', '형/파/해', '신살', '육친', '공망/태월/원진/귀문/현침']);

const flippedCards = ref([false, false, false]);

const toggleCard = (index) => {
  flippedCards.value[index] = !flippedCards.value[index];
}


function createResultArray(bb, aa, keys) {
  const finalResult = [];
  const countArray = Array(aa.length).fill(0); // 기본값으로 [0, 0, 0, 0] 설정

  // keys가 배열인지 확인하고, 배열이 아닐 경우 배열로 변환
  const keyArray = Array.isArray(keys) ? keys : [keys];

  // 각 키에 대해 데이터 처리
  keyArray.forEach(key => {
    const items = bb[key];

    // items의 데이터에서 2개씩 가져와서 처리
    for (let i = 0; i < items?.length; i += 2) {
      const row = [];

      for (let j = 0; j < aa.length; j++) {
        const pos = aa[j];
        const match1 = items[i] && items[i].pos === pos ? items[i].type : null;
        const match2 = items[i + 1] && items[i + 1].pos === pos ? items[i + 1].type : null;

        // match1 또는 match2가 존재하면 해당 type을 추가하고, 없으면 '공백'
        if (match1) {
          row.push(match1);
          countArray[j]++; // 공백이 아닌 경우 해당 인덱스 증가
        } else if (match2) {
          row.push(match2);
          countArray[j]++; // 공백이 아닌 경우 해당 인덱스 증가
        } else {
          row.push('');
        }
      }

      finalResult.push(row);
    }
  });

  // 최종 결과에 countArray 추가
  if (countArray.every(count => count === 0)) {
    finalResult.push(['none']);
  } else {
    finalResult.push(countArray);
  }

  return finalResult;
}
//const galleryData = reactive(dataSet(props.prDataAll));
const galleryData = ref([]);

const birthSkyTxt = ref([]);
const birthEarthTxt = ref([]);

function dataSet(prDataAll) { 
  const salSky = prDataAll.sal;
  //console.log("line : 138 ----------------------------salSky")
  //console.log(salSky)

  const getSortedArray = (data, isDesc) => {
    if (isDesc) {
      return [
        [...data.hour],
        [...data.day],
        [...data.month],
        [...data.year]
      ];
    } else {
      return [
        [...data.year],
        [...data.month],
        [...data.day],
        [...data.hour]
      ];
    }
  };

  const sixFamilySky = getSortedArray(prDataAll.six_family?.sky, props.prSortMode === 'desc');
  const sixFamilyEarth = getSortedArray(prDataAll.six_family?.earth, props.prSortMode === 'desc');
 
  let aa = [];
  if(props.prSortMode === 'desc'){
    aa = ['hour', 'day', 'month', 'year'];
  }else{
    aa = ['year', 'month', 'day', 'hour'];
  }


  const hapDataSky = createResultArray(prDataAll.hap?.sky, aa, 'hap');
  const hapDataEarth = createResultArray(prDataAll.hap?.earth, aa, 'hap');

  const chungDataSky = createResultArray(prDataAll.hap?.sky, aa, 'chung');
  const chungDataEarth = createResultArray(prDataAll.hap?.earth, aa, 'chung');

  //const hyungDataSky = createResultArray(prDataAll.hap?.sky, aa, 'hyung');
  //const hyungDataEarth = createResultArray(prDataAll.hap?.earth, aa, 'hyung');

  // 데이터 합치기
  const combinedData = [
    ...(Array.isArray(prDataAll.hap?.sky?.hyung) ? prDataAll.hap.sky.hyung : []),
    ...(Array.isArray(prDataAll.hap?.earth?.hyung) ? prDataAll.hap.earth.hyung : [])
  ];

  const combinedHyung = createResultArray({ hyung: combinedData }, aa, 'hyung');

  //const combinedHyung = hyungDataSky.concat(hyungDataEarth);

  //const paDataSky = createResultArray(prDataAll.hap?.sky, aa, 'pa');
  //const paDataEarth = createResultArray(prDataAll.hap?.earth, aa, 'pa');
  //const haeDataSky = createResultArray(prDataAll.hap?.sky, aa, 'hae');
  //const haeDataEarth = createResultArray(prDataAll.hap?.earth, aa, 'hae');
  const combinedDataPa = [
    ...(Array.isArray(prDataAll.hap?.sky?.pa) ? prDataAll.hap.sky.pa : []),
    ...(Array.isArray(prDataAll.hap?.earth?.pa) ? prDataAll.hap.earth.pa : []), 
    ...(Array.isArray(prDataAll.hap?.sky?.hae) ? prDataAll.hap.sky.hae : []),
    ...(Array.isArray(prDataAll.hap?.earth?.hae) ? prDataAll.hap.earth.hae : []), 
  ];
  console.log("combinedDataPa")
  console.log(combinedDataPa)


  const combinedPa = createResultArray({ pa: combinedDataPa}, aa,  ['pa', 'hae']);
  console.log("combinedPa")
  console.log(combinedPa)

  //const combinedPa = [].concat(paDataSky, paDataEarth, haeDataSky, haeDataEarth);

  const gongmangData = prDataAll.gongmang.map(item => item.value).join('');
  const taewonData = prDataAll.taewon.map(item => item.value).join('');

  const newData = ref([
    [hapDataSky, hapDataEarth],
    [chungDataSky, chungDataEarth],
    [combinedHyung, combinedPa],
    ['', salSky],
    [sixFamilySky, sixFamilyEarth],
    [gongmangData, taewonData, prDataAll.wonjinsal, prDataAll.guimunsal, prDataAll.skyhunchimsal, prDataAll.earthhunchimsal],
  ]);

  //console.log("line : 138 ----------------------------newData")
  //console.log(newData)

  galleryData.value = newData.value;

  const sortOrder = props.prSortMode !== 'desc' ? aa.slice().reverse() : aa;
  birthSkyTxt.value = sortOrder.map(unit => prDataAll.birthganji.sky[unit].value);
  birthEarthTxt.value = sortOrder.map(unit => prDataAll.birthganji.earth[unit].value);


  /*
  return {
    galleryData
  };
  */
}




const galleryDataTitle = ref([ // 초기값을 배열로 설정
  ['천간합', '지지합'],
  ['천간충', '지지충'],
  ['형', '파/해'],
  ['', ''],
  ['', ''],
  ['공망', '태월', '원진', '귀문', '천간현침', '지지현침']
]);
// 반응형 객체 생성

watch(() => props.prDataAll, (newVal) => {
  dataSet(newVal);
}, { deep: true });


//순서변경 로직 추가
watch(
  () => props.prSortMode,
  (newValue) => {
    dataSet(props.prDataAll);
  },
  { immediate: true } // 컴포넌트가 처음 마운트될 때도 호출
);

/*
const reverseGalleryData = () => {
  galleryData.value = galleryData.value.map(item => {
    return item ? [...item].reverse() : []; // 새로운 배열로 reverse
  });
};
*/

</script>
<template>
  <div v-for="(title, index) in galleryTitles" :key="index" class="card-container">
    <div class="card"
      :class="{ flipped: flippedCards[index]}"
      @click="toggleCard(index)">
      <div class="card-front">
        <div class="tit">
          <span class="tit-txt">{{ title }}  </span>
          <button
            class="btn-detail"
          >
            조회상세버튼
          </button>
        </div>
        <div class="data-detail scroll " :class="(index === 5 ) ? 'sec-flex' : '' "> 
          <ManseCardItem
            :prData = galleryData[index]
            :prIndex = index
            :prGalleryDataTitle = galleryDataTitle
            :prBirthSkyTxt=birthSkyTxt
            :prBirthEarthTxt=birthEarthTxt
          />
        </div>
      </div>
      <div class="card-back">
        <div class="data-detail-back">
          <div class="tit">
            <span class="tit-txt">{{ title}}</span>
            <button
              class="btn-detail"
            >
              조회상세버튼
            </button>
          </div>
          <div class="data-exp-01">
            <div class="">
              신(神)<br> 신은 한자로 '귀신'이라는 뜻으로, 평범한 상태에서 왠지 마치 귀신같이 좋은 운이 온다는 것을 의미한다. 귀인(貴人)이라고도 하는데, 이것도 마찬가지로 사람에게 귀한 운이 온다는 뜻이다. 가장 대표적인 신으로 천을귀인(天乙貴人), 천덕귀인(天德貴人), 월덕귀인(月德貴人)이 있다. 이 세 가지 말고도 월공귀인(月空貴人), 문창귀인(文昌貴人), 천의귀인(天醫貴人), 암록귀인(暗祿貴人), 삼기귀인(三奇貴人) 등이 있다.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>