<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { saveMemo, delMemo, modMemo } from '@/api/UserGroup';

  //popup
  import PopGroupDel from '@/pages/saju/components/PopGroupDel.vue';
  import fiveMixin from '@/composables/fiveMixin';
  import useFunctions from '@/composables/useFunctions';

  //Alert
  import AlertComponent from '@/pages/manse/components/Alert.vue';


  const { ...five } = fiveMixin();
  const { ...useFun } = useFunctions();

  const isDialogVisibleSetting = ref(true);
  const emit = defineEmits();

  const props = defineProps({
    prSelectedMemo: {
      type: Object,
      default: null
    },
    prMemoModeType:{
      type: String,
      default: null
    },
    prSelectedMemoVal:{
      type: Object,
      default: null
    }
  })
  const content = ref('');
  const isPopGroupDelBox = ref(false);

  const close = () => {
    emit('close');
  };
  const saveMemoFirstSubmit = () =>{
    close();
    emit('saveMemoFirst', content.value);
  }
  const updateMemoEdit = () =>{
    emit('updateMemoEdit');
  }


  function saveMemoSubmit() {
    if (content.value.length === 0) {
      showDialogAlert('내용을 입력해주세요.');
      return;
    }
    const param = {};
    const paramUser = {};
    paramUser.guestUserId = props.prSelectedMemo.guestUserId;
    param.guestUser = paramUser;
    param.content = content.value;
    saveMemo(param)
      .then(() => {
        showDialogAlert('등록 되었습니다.');
        close();
        const item = {};
        item.guestUserId = props.prSelectedMemo.guestUserId;
        emit('saveMemoFirst', content.value);
        emit('listGroupMemo', props.prSelectedMemoVal);
      })
      .catch((error) => {
        alert("65>>"+error?.response?.data?.message);
      });
    //emit('saveMemoFirst', content.value);
  }

  onMounted(async () => {
    if(props.prSelectedMemo.content){
      content.value = props.prSelectedMemo.content;
    }
  });

  const deleteMemo = () =>{
    const formParams = {};
    formParams.guestUserMemoIds = props.prSelectedMemo.guestUserMemoId;

    delMemo(formParams)
      .then(() => {
        close();
        emit('listGroupMemo');
      })
      .catch((error) => {
        alert("delMemo error : "+error.response?.data?.message);
      });
  }
  const updateMemo = () => {
    if (content.value.length === 0) {
      showDialogAlert('내용을 입력해주세요.');
      return;
    }
    const param = {};
    const paramUser = {};
    paramUser.guestUserId = props.prSelectedMemo.guestUserId;
    param.guestUser = paramUser;
    param.content = content.value;
    param.guestUserMemoId = props.prSelectedMemo.guestUserMemoId;
    modMemo(param)
      .then(() => {
        showDialogAlert('수정 되었습니다.');
        close();
        emit('listGroupMemo', '');
      })
  }



  const dialogAlert = ref(null);
  const dialogTitle = ref('');
  const dialogText = ref('');

  const showDialogAlert = (title, text) => {
    dialogTitle.value = title;
    dialogText.value = text;
    dialogAlert.value.openAlertDialog();
  };
</script>
<template>
  <VDialog
    v-model="isDialogVisibleSetting"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
  >
    <VCard >
      <DialogCloseBtn
        variant="text"
        size="default"
        @click="close"
      />
      <div
        class="pop-inner"
      >
        <div class="pop-tit-box">
          <div class="btn-left">
            <button
              v-if="prMemoModeType === 'view' || prMemoModeType === 'edit'"
              class="btn-del"
              @click=" deleteMemo()"
            >
              삭제
            </button>
          </div>
          <div class="">
            메모 작성
          </div>
          <div
            class="close-button"
            @click="close()"
          >
            Close
          </div>
        </div>
        <div class="memo-hd-box">
          <div class="tits">
            {{ useFun.getUserNameTxt(prSelectedMemoVal?.guestUserName) }}님 ({{ useFun.getGenderTxt(prSelectedMemoVal?.gender) }})  {{ prSelectedMemoVal?.ageKor }}세 (만{{ prSelectedMemoVal?.age }}세)
          </div>
          <div class="txts mem-birth-box">
            <div class="">
              <span class="tits orange">양력</span>
              <span class="txts">{{ useFun.formatCustomDate(prSelectedMemoVal?.solar) }} ({{
                useFun.getDayOfWeekOne(prSelectedMemoVal?.solar)
              }}) {{ useFun.getBirthTimeTxt(prSelectedMemoVal?.birthTime) }}
              </span>
            </div>
            <div class="">
              <span class="tits blue">음력</span>
              <span class="txts">{{ useFun.formatCustomDate(prSelectedMemoVal?.lunar) }}</span>
            </div>
          </div>
        </div>
        <div
          class="pop-body pd-large-btm"
        >
          <div
            v-if="prMemoModeType === 'reg' || prMemoModeType === 'reg_first' ||prMemoModeType === 'edit' ||prMemoModeType === 'list'"
            class="textbox-line"
          >
            <textarea
              v-model="content"
              class="textarea"
              maxlength="2000"
            />
            <div class="text-count">
              {{ content?.length }} / 2000
            </div>
          </div>
          <div
            v-if="prMemoModeType === 'view'"
            class="textbox-line no-line"
          >
            {{ content }}
          </div>
          <div class="btn-com">
            <button
              v-if="prMemoModeType === 'view'"
              class="btn-nor submit"
              @click=" updateMemoEdit()"
            >
              수정
            </button>
            <button
              v-if="prMemoModeType === 'edit'"
              class="btn-nor submit"
              @click=" updateMemo()"
            >
              수정
            </button>
            <VBtn
              v-if="(prMemoModeType === 'reg' || prMemoModeType === 'list')"
              :class="[ 'btn-nor submit',{ 'disabled-button': !content }]"
              :disabled="!content"
              @click="saveMemoSubmit()"
            >
              등록
            </VBtn>
            <VBtn
              v-if="prMemoModeType === 'reg_first'"
              :class="[ 'btn-nor submit',{ 'disabled-button': !content }]"
              :disabled="!content"
              @click="saveMemoFirstSubmit()"
            >
              등록
            </VBtn>
          </div>
        </div>
      </div>
    </VCard>

  </VDialog>

  <!-- 그룹 삭제확인 메세지 -->
  <PopGroupDel
    v-if="isPopGroupDelBox"
    @del="handleDel()"
    @dcancel="dcancdelPopGroup()"
  />
  
  <!--Alert창-->
  <AlertComponent
    ref="dialogAlert"
    :title="dialogTitle"
    :text="dialogText"
  />
</template>