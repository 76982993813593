import axios from 'axios';

const endpoint = import.meta.env.VITE_APP_GW_URL
const systemId = import.meta.env.VITE_APP_SYSTEMID

/** 에세이 목록/상세 보기 */
export const getEssay = async ({essayId, body}) => {
  const subPath = essayId ? `/${essayId}` : ''
  const params = body ? new URLSearchParams(clarify(body)) : {systemId}
  const response = await axios.get(`${endpoint}/eoway/essay${subPath}`, { params });
  return response.data;
}
/** 에세이 상세 보기 */
export const getEssayView = async (essayId, body) => {
  // const params = prepareParams(body);
  const response = await axios.get(`${endpoint}/eoway/essay/${essayId}`, { params });
  return response.data
}
/** 에세이 좋아요 업데이트 */
export const patchEssayLike = async (essayId) => {
  const params = new URLSearchParams(clarify({
    likeType: 'ESSAY',
    systemId,
  }))  
  const response = await axios.patch(`${endpoint}/like/${essayId}`, null, { params })
  return response.data
}
/** 사주시계 정보 요청 */
export const sajuWatch = async (body) => {
  // const params = body ? new URLSearchParams(clarify(body)) : {};
  const params = body ? new URLSearchParams(clarify(body)) : {systemId}
  params.append('systemId', systemId);
  const response = await axios.get(`${endpoint}/fast/sajuWatch/`, { params })
  return response.data
}
/** 역학달력 정보 요청 */
export const getSajuCalendar = async (body) => {
  const params = body ? new URLSearchParams(clarify(body)) : {};
  const response = await axios.get(`${endpoint}/fast/sajuCalendar/`, { params })
  return response.data
}
/** 역학달력 필터 */
export const calendarFilter = async ({body, id}) => {
  /// eoway/userFilter/users/{userId}
  const headers = generateHeaders();
  const params = body ? new URLSearchParams(clarify(body)) : {};
  params.append('systemId', this.systemId);
  return axios.get(`${this.endpoint}/eoway/userFilter/users/${id}`, { params, headers }).then((response) => response.data);
}