<template>
  <VRow v-model="isAlertDialogVisible" class="v-dialog-alert" :class="isAlertDialogVisible ? 'active' : ''">
    <div class="back-full-layer">
      <div class="alert-wrap">
        <div class="tit-box">
          {{ title }}
        </div>
        <div class="btn-box">
          <!-- <button
            class="btn-nor bottom left cancel"
            @click="cancel"
          >
            취소
          </button> -->
          <button
            class="btn-nor"
            @click="closeAlertDialog"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  </VRow >
</template>

<script setup>
import { ref, defineProps } from 'vue';

const router = useRouter();
const props = defineProps({
  title: String,
  text: String,
  nextUrl: String,
});

const isAlertDialogVisible = ref(false);

const closeAlertDialog = () => {
  if(props.nextUrl !== ''){
    isAlertDialogVisible.value = false;
    router.push(props.nextUrl);
    return;
  }
  isAlertDialogVisible.value = false;
};

const openAlertDialog = () => {
  isAlertDialogVisible.value = true; 
};

// 다이얼로그를 여는 메서드를 외부에서 호출할 수 있도록
defineExpose({ openAlertDialog });
</script>
<style scoped>
.dialog-title {
  text-align: center; /* 가운데 정렬 */
}
</style>
