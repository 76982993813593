// src/store/modules/user.js
import { createStore } from "vuex"
import { jwtDecode } from "jwt-decode"
import { getToken, setToken, removeToken, setRefresh } from "@/utils/auth"
import AuthApi from "@/api/Auth"

const userModule = {
  namespaced: true,
  state: () => {
    const token = getToken()
    try {
      const data = jwtDecode(token)
      return {
        token,
        name: data.name,
        id: data.id,
        data,
        errorMsg,
      }
    } catch (e) {
      return {
        token: "",
        name: "",
        id: "",
        data: {},
        errorMsg: null,
      }
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      setToken(token)
      state.token = sessionStorage.getItem("X-Token")
    },
    SET_REFRESH: (state, refresh) => {
      setRefresh(refresh)
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_ID: (state, userId) => {
      state.id = sessionStorage.getItem("authUserId")
      //localStorage.setItem('authUserId', userId);
      sessionStorage.setItem("authUserId", userId)
    },
    SET_AUTHS: (state, auths) => {
      state.auths = auths
    },
    SET_DATA: (state, data) => {
      state.data = data
    },
    SET_ERROR_MSG(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    CLEAR(state) {
      state.token = ""
      state.auths = []
      state.name = ""
      state.id = ""
      state.data = {}
      removeToken()
    },
  },
  actions: {
    async login({ commit, dispatch }, { loginId, loginPw }) {
      commit("CLEAR")
      try {
        const data = await AuthApi.login(loginId, loginPw)
        commit("SET_TOKEN", data.access_token)
        commit("SET_REFRESH", data.refresh_token)
        commit("SET_NAME", data.name)
        commit("SET_ID", data.id)
        commit("SET_DATA", data)
        sessionStorage.setItem("userInfo", JSON.stringify(data))
        sessionStorage.setItem("X-Token", data.access_token)
        sessionStorage.setItem("refresh_token", data.refresh_token)
        sessionStorage.setItem("authUserId", data.id)

        // dispatch("error/hide", {}, { root: true })
        // commit("app/resetToast", {}, { root: true })
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const { errorMessage } = error.response.data;
          commit('SET_ERROR_MSG', errorMessage);
        } else {
          commit('SET_ERROR_MSG', '로그인에 실패했습니다.');
        }
        //throw error
      }
    },
    logout: ({ commit }) => {
      commit("CLEAR")
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("authUserId")
      sessionStorage.removeItem("refresh_token")
      sessionStorage.removeItem("X-Token")
    },
  },
  getters: {
    token: () => sessionStorage.getItem("X-Token"),
    name: (state) => state?.name || "",
    id: () =>  sessionStorage.getItem("authUserId"),
    data: () => JSON.parse(sessionStorage.getItem("userInfo")),
    auths: (state) => state?.auths,
  },
  /*
  getters: {
    token: (state) => state?.token,
    name: (state) => state?.name || "",
    id: (state) => state?.id || "",
    auths: (state) => state?.auths,
    data: (state) => state?.data,
  },
  */
}

export default userModule
