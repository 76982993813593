<script setup>
definePage({ meta: { layout: 'empty' } })

import { useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();
const pathVal = ref('');

onMounted(async () => {
  pathVal.value = route.query.path;
  router.push(pathVal.value);
})
</script>
<template>
  <div class="empty-page">blank
  </div>
</template>
<style lang="scss">
@use "@nstyles/temporary.scss";

.empty-page {
  width: 100%;
  height: 100vh;
  background-color: cornflowerblue;
  text-align: center;
  font-size: 30px;
}
</style>