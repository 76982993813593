<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { getMemoList } from '@/api/UserGroup';

  //popup
  import PopGroupDel from '@/pages/saju/components/PopGroupDel.vue';
  import fiveMixin from '@/composables/fiveMixin';
  import useFunctions from '@/composables/useFunctions';

  const { ...five } = fiveMixin();
  const { ...useFun } = useFunctions();

  const isDialogVisibleSetting = ref(true);
  const emit = defineEmits();

  const props = defineProps({
    prMemoListData: {
      type: Object,
      default: null
    },
    prSelectedMemo: {
      type: Object,
      default: null
    },
    prMemoModeType:{
      type: String,
      default: null
    },
    prSelectedMemoVal:{
      type: Object,
      default: null
    }

  })
  const memoTxt = ref('');
  const isPopGroupDelBox = ref(false);

  const close = () => {
    emit('close');
  };
  const openMemoListReg = () =>{
    emit('open', props.prSelectedMemoVal);
  }
  const listData = ref({});
  const memoData = ref([]);
  /*
  const getMemoListData = async () => {
    alert('38>>')
    try {
      const params = {}
      if(memoTxt.value) params.content = memoTxt.value
      params.guestUserId = props.prSelectedMemo.guestUserId;
      params.sort ='createdAt.desc';
      const data = await getMemoList(params); // 그룹리스트 조회
      memoData.value = data.content;
      console.log(params);
      alert('47>>')

      // listData 초기화
      Object.keys(listData.value).forEach((key) => {
        delete listData.value[key];
      });

      // 데이터 그룹화
      memoData.value.forEach((data) => {
        const createdAt = new Date(data.createdAt);
        const monthYear = `${createdAt.getFullYear()}-${(`0${createdAt.getMonth() + 1}`).slice(-2)}`;

        if (!listData.value[monthYear]) {
          listData.value[monthYear] = [];
        }

        listData.value[monthYear].push(data);
      });
    } catch (error) {
      console.error('Failed to load users:', error);
    }
  }
  */

  const handleSearchMemo = async () => {
    //await getMemoListData();
    await emit('listMemo', memoTxt.value);
  }
  onMounted(async () => {
    //await getMemoListData();
    await emit('listMemo');
  });
  // 하이라이트 함수
  function highlightText(content, highlight) {
    if (!highlight) return content; // 하이라이트할 텍스트가 없으면 원본 반환
    const regex = new RegExp(`(${highlight})`, 'gi'); // 대소문자 구분 없이 하이라이트할 텍스트를 정규 표현식으로 설정
    return content.replace(regex, '<span class="highlight">$1</span>'); // 하이라이트된 텍스트에 스타일 적용
  }

  const handelView = (item) => {
    emit('openMemoView', props.prSelectedMemoVal, item);
  }
</script>
<template>
  <VDialog
    v-model="isDialogVisibleSetting"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
  >
    <VCard >
      <DialogCloseBtn
        variant="text"
        size="default"
        @click="close"
      />
      <div
        class="pop-inner"
        style="height: 600px;"
      >
        <div class="pop-tit-box">
          <div class="">
            {{ useFun.getUserNameTxt(prSelectedMemo?.guestUserName) }}님 메모
          </div>
          <div
            class="close-button"
            @click="close()"
          >
            Close
          </div>
        </div>
        <div class="pop-body pd-large">
          <div class="pop-search-box">
            <VTextField
              v-model="memoTxt"
              class="input-box"
              variant="underlined"
              clearable
              maxlength="15"
              placeholder="내용을 검색하세요"
              @keyup.enter="handleSearchMemo()"
              @focus="wordFocus=true"
              @blur="wordFocus=false"
            ></VTextField>
            <VBtn
              :class="[ 'obtn',{ 'disabled-button': !memoTxt }]"
              :disabled="!memoTxt"
              @click="handleSearchMemo()"
            >
              검색
            </VBtn>
          </div>
          <div
            class="memo-list-box scroll"
            style="height: 390px;"
          >
            <div
              v-for="(monthData, month) in prMemoListData"
              :key="month"
              class="month-row-box"
            >
              <div class="month-tit">
                {{ month }}
              </div>
              <!-- 각 월에 해당하는 데이터 리스트 표시 -->
              <div
                v-for="data in monthData"
                :key="data?.guestUserMemoId"
                class="month-item-box"
                @click="handelView(data)"
              >
                <div class="tit-cont"  v-html="highlightText(data.content, memoTxt)"></div>
                <div class="date">
                  작성일 : {{ useFun.formatDateHH(data?.createdAt) }}
                </div>
              </div>
            </div>
          </div>
          <div class="btn-btm-right-box">
            <button
              class="btn-rd-plus"
              @click="openMemoListReg"
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </VCard>

  </VDialog>

  <!-- 그룹 삭제확인 메세지 -->
  <PopGroupDel
    v-if="isPopGroupDelBox"
    @del="handleDel()"
    @dcancel="dcancdelPopGroup()"
  />
</template>