import { createApp } from "vue"
import App from "@/App.vue"
import { registerPlugins } from "@core/utils/plugins"
import store from "@/store"
import { setupInterceptors } from "@/api/interceptor/interceptor.js"

// Styles
import "@core/scss/template/index.scss"
import "@styles/styles.scss"
import "quill/dist/quill.bubble.css"

import axios from "axios"

export const DEV_MODE = import.meta.env.VITE_APP_MODE === 'development'

axios.defaults.baseURL = import.meta.env.VITE_APP_GW_URL // 개발서버 API 경로

// Create vue app
const app = createApp(App)

// Register plugins
registerPlugins(app)

// Vuex and Vue Router
app.use(store)

// Setup Axios interceptors
setupInterceptors()

// Mount vue app
app.mount("#app")
