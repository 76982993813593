<script setup>
  import { sajuKor } from '@/api/Eoway';
  import fiveMixin from '@/composables/fiveMixin';

  const emit = defineEmits();
  const { ...five } = fiveMixin();

  const popGanjiBox = true;
  const ganjiTxt = ref('');
  const birthDataDays = ref({});
  const birthganjiData = ref(null);
  const checkedItems = ref([]);
  const selectedIndex = ref(null);
  const close = () => {
    emit('close');
  }
  const isChecked = (index) => {
    return checkedItems.value.includes(index);
  }
  const toggleCheckbox = (index) => {
    // 체크박스 상태를 토글하고 checkedItems 배열을 업데이트
    if (checkedItems.value.includes(index)) {
      checkedItems.value.splice(checkedItems.value.indexOf(index), 1); // 이미 체크된 요소를 다시 클릭하면 체크 해제
    } else {
      checkedItems.value = [index]; // 새로운 요소를 클릭하면 이전에 선택된 요소를 모두 제거하고 새로운 요소를 추가
      selectedIndex.value = index;
    }
  }
  const handleSearchGanji = async () => {
    const formParams = {};
    formParams.searchKro = ganjiTxt.value;
    const data = await sajuKor(formParams);
    birthDataDays.value = data.days;
    birthganjiData.value = data.birthganji;
  }
  function searchInput() {
    emit('searchinput', birthDataDays.value[selectedIndex.value]);
    close();
  }

</script>
<template>
  <VDialog
    v-model="popGanjiBox"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
  >
    <VCard >
      <div
        class="pop-inner"
        style="height: 600px;"
      >
        <div class="pop-tit-box">
          <div class="">
            사주 간지 입력
          </div>
          <DialogCloseBtn
            variant="text"
            size="default"
            @click="close"
          />
        </div>
        <div class="pop-body pd-large">
          <div class="">
            한글팔자로 입력
          </div>
          <div class="pop-search-box">
            <div class="com-input-wrap">
              <VTextField
                v-model="ganjiTxt"
                class="input-box"
                variant="underlined"
                clearable
                maxlength="15"
                placeholder="예) 갑자을축병인정묘"
                @keyup.enter="handleSearchGanji()"
                @focus="wordFocus=true"
                @blur="wordFocus=false"
              ></VTextField>
            </div>
            <VBtn
              :class="[ 'obtn',{ 'disabled-button': !ganjiTxt }]"
              :disabled="!ganjiTxt"
              @click="handleSearchGanji()"
            >
              검색
            </VBtn>
          </div>
          <div
            v-if="birthganjiData"
            class="pop-ganji-result"
          >
            <div class="pop-ganji-list-box">
              <ul
                class="birth-item-box"
                v-for="(item, index) in ['hour', 'day', 'month', 'year']" :key="index"
              >
                <li><span class="txt1">{{ item === 'year' ? '년' : item === 'month' ? '월' : item === 'day' ? '일' : '시' }}</span></li>
                <li><span class="txt2">{{ birthganjiData?.sky[item]?.six }}</span></li>
                <li >
                  <span
                    class="txt-round"
                    :class="[five.getColorFive(birthganjiData?.sky[item].value)]"
                  >
                    <em
                      class="chn"
                    > {{ birthganjiData?.sky[item].value }} </em>
                  </span>
                </li>
                <li>
                  <span
                    class="txt-round"
                    :class="[five.getColorFive(birthganjiData?.earth[item]?.value) ]"
                  >
                    <em
                    > {{ birthganjiData?.earth[item]?.value }} </em>
                  </span>
                </li>
                <li><span class="txt2">{{ birthganjiData?.earth[item]?.six }}</span></li>
              </ul>
            </div>
            <div class="day-list-box scroll">

              <div
                v-for="(item, index) in birthDataDays"
                :key="index"
                class="row-box"
                :class="{ 'active': isChecked(index) }"
              >
                <div class="txt01">
                  양력 {{ item.year }}년 {{ item.month }}월 {{ item.day }}일 {{ getDayOfWeek(item.year, item.month, item.day) }} {{ getTimeTxt(item.time) }}
                </div>
                <div class="txt02">
                  음력 {{ item.year_m }}년 {{ item.month_m }}월 {{ item.day_m }}일  {{ getDayOfWeek(item.year, item.month, item.day) }} {{ getTimeTxt(item.time) }}
                </div>
                <div class="btn-r">
                  <input
                    :id="'radio' + index"
                    name="birthItems"
                    type="radio"
                    class="checkbox"
                    :value="item"
                    @change="toggleCheckbox(index)"
                  ><label
                    :for="'radio' + index"
                    class="label"
                  >체크</label>
                </div>
              </div>
            </div>
            <div class="btn-com">
              <button
                class="btn-nor"
                :class="{ 'active': checkedItems?.length > 0 }"
                @click="searchInput(checkedItems)"
              >
                완료
              </button>
            </div>
          </div>
          <div
            v-if="birthganjiData === null"
            class="info-box-01"
          >
            <div class="info-box">
              <div class="tit-info">
                이용 안내
              </div>
              <div class="txt-box">
                <div class="">
                  사주간지로 입력 시 1900년 ~ 2100년까지 생년월일을 입력할 수 있습니다.
                </div>
                <div class="">
                  한글로 년주 월주 일주 사주를 입력하신 후 확인 버튼을 클릭하여, 원하는 생년월일을 선택하시기 바랍니다.
                </div>
                <div class="">
                  예)갑자을축병인정묘
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>