<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">내 정보</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>MY</li>
          <li>내 정보</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="tit">계정 정보</div>
      <div class="myinfo-dt-box">
        <dl>
          <dt>계정/아이디</dt>
          <dd>dangam12345</dd>
        </dl>
        <dl>
          <dt>비밀번호</dt>
          <dd>**************</dd>
        </dl>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="tit">기본 정보</div>
      <div class="myinfo-dt-box">
        <dl>
          <dt>이름</dt>
          <dd>김낭만</dd>
        </dl>
        <dl>
          <dt>닉네임</dt>
          <dd>-</dd>
        </dl>
        <dl>
          <dt>이메일</dt>
          <dd></dd>
        </dl>
        <dl>
          <dt>휴대폰 번호</dt>
          <dd>010-1*3*-56**</dd>
        </dl>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="tit">기본 정보</div>
      <div class="myinfo-dt-box">
        <dl>
          <dt>가입일</dt>
          <dd></dd>
        </dl>
        <dl>
          <dt>최종 로그인</dt>
          <dd>010-1*3*-56**</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
