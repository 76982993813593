<script setup>
import { getBoardName } from "@/composables/functions"
import Gnb from "@/components/Gnb.vue"
import { BoardList, BoardView, BoardWrite, EssayList } from "@/components/board"

const board = ref(null)
const boardId = ref(0)
const articleId = ref(0)
const route = useRoute()

const setData = () => {
  boardId.value = getBoardName(route.params.board ?? "notice", "boardId")
  board.value = route.params.board ?? "notice"
  articleId.value = route.params.articleId ?? 0
}

onMounted(() => {
  setData()
})

watch(
  () => route.params.board,
  () => setData()
)
watch(
  () => route.params.boardId,
  () => setData()
)
watch(
  () => route.params.articleId,
  () => setData()
)
</script>

<template>
  <section class="inner-base-2">
    <nav class="cust-gnb">
      <Gnb />
    </nav>
    <section class="cust-body">
      <div v-if="boardId">
        <BoardList v-if="!articleId && board !== 'essay'" :board="board" :board-id="boardId" />
        <BoardView
          v-if="articleId && articleId !== 'write' && board !== 'essay' "
          :board="board"
          :board-id="boardId"
          :article-id="articleId"
        />
        <BoardWrite
          v-if="articleId === 'write' && board !== 'essay'"
          :board="board"
          :board-id="boardId"
          :article-id="articleId"
        />
        <EssayList
          v-if="!articleId && board === 'essay'"
          :board="board"
          :board-id="boardId"
        />
      </div>
    </section>
  </section>
</template>
