<script setup>
import IconMypage from "@/components/svg/IconMypage.vue"
import IconMySubscr from "@/components/svg/IconMySubscr.vue"
import IconMyInquiry from "@/components/svg/IconMyInquiry.vue"
import IconMyLife from "@/components/svg/IconMyLife.vue"
import IconArrow01 from "@/components/svg/IconArrow01.vue"
import { useRoute } from 'vue-router'

const route = useRoute()
 
const gnbMenuItems = [
  { 
    name: "My", 
    link: "/mypage/myinfo", 
    icon: "icon-myinfo",
    subItems: [
      { name: "내 정보", link: "/mypage/myinfo" },
      { name: "부가 정보", link: "/mypage/myinfoMore" }
    ]
  },
  { 
    name: "구독", 
    link: "/mypage/subscr", 
    icon: "icon-subscr",
    subItems: [
      { name: "서비스 안내", link: "/mypage/subscr" },
      { name: "낭만 저장소", link: "/mypage/subscrRepo" },
      { name: "결제내역 / 포인트", link: "/mypage/subscrPoint" }
    ]
  },
  { 
    name: "문의", 
    link: "/mypage/inquiry", 
    icon: "icon-inquiry",
    subItems: [
      { name: "문의하기", link: "/mypage/inquiry" },
      { name: "문의내역", link: "/mypage/inquiryList" }
    ]
  },
  { 
    name: "라이프캘린더", 
    link: "/mypage/life", 
    icon: "icon-life",
    subItems: [
      { name: "등록", link: "/mypage/life" },
      { name: "보기", link: "/mypage/lifeView" }
    ]
  },
]
const isActive = (link) => route.path.split('/').slice(0, 3).join('/') === link.split('/').slice(0, 3).join('/')
</script>

<template> 
  <div class="gnb-menu-box">
    <div v-for="(menu, index) in gnbMenuItems" :key="index" class="depth01">
      <RouterLink class="gnb-link" :to="menu.link" :class="{ active: isActive(menu.link) }">
        <IconMypage v-if="menu.icon === 'icon-myinfo'" />
        <IconMySubscr v-if="menu.icon === 'icon-subscr'" />
        <IconMyInquiry v-if="menu.icon === 'icon-inquiry'" />
        <IconMyLife v-if="menu.icon === 'icon-life'" />
        <span>{{ menu.name }}</span>
      </RouterLink>
      <div v-if="menu.subItems.length > 0" class="sub-menu">
        <div v-for="(subItem, subIndex) in menu.subItems" :key="subIndex">
          <RouterLink class="sub-link" :to="subItem.link">
            <span>{{ subItem.name }}</span>
            <i class=""><IconArrow01/> </i>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>  
</template>
