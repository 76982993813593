<script setup>
import { useRouter } from "vue-router"
import { getArticles } from "@/api/ArticleApi";
import ComLatest from "@/components/manse/ComLatest.vue";
import {
  Calendar,
  Carousel,
  Watch
} from "@/components/main"
import { DEV_MODE } from "@/main";

definePage({ meta: { layout: "main" } });

const props = defineProps({
  option: {
    type: Object,
    default: () => ({}),
  },
  height: {
    type: String,
    default: '700px',
  },
  pgmode: {
    type: String,
    default: '',
  },
  data: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['updateTheme'])

/** iconTheme 정보 헤더로 전달 */
const updateTheme = (newTheme) => {
  emit('updateTheme', newTheme)
}

const router = useRouter()

const calendarOption = ref({
  isKorChecked: false,
  isLoudarChecked: true,
  isHolidayChecked: true,
  isSeasonChecked: true,
});
const calendarHeight = ref(440);
const paramData = ref({
  birthStart: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  searchKeywords: '',
  sortOption: null,
  userGroupId: null,
  fiveEles: null,
  size: 2,
  sort: null,
});
/** iconTheme */
const selectedSlideData = ref()

/** 새소식 데이터 */
const newsData = ref({});

onMounted(() => {
  getNoticeList();
});

/** 게시판 버튼 클릭 시 */
const onClickBoardButton = async ({boardName}) => {
  router.push(`/customer/${boardName}`)
}
/** 공지사항 요청 */
const getNoticeList = async () => {
  try {
    const result = await getArticles(
      {
        boardId: 2325,
        params: {
          page: 1,
          size: 3,
        }
      })
    newsData.value = result.content;
  } catch (e) {
    DEV_MODE && console.log('main getNoticeList error:', e)
  }
}
/** 새소식 - 게시물로 이동 */
const onClickArticle = ({boardTitle, articleId}) => {
  let boardName = ''
  switch (boardTitle) {
    case '공지사항':
    case '만세력_공지사항':
      boardName = 'notice'
      break;
    default:
      boardName = 'notice'
      break
  }

  if (articleId) router.push(`/customer/${boardName}/${articleId}`)
  else router.push(`/customer/${boardName}`)
}

const printManse = () => {
  alert('사주조회 후 이용하여 주시기 바랍니다.');
};
</script>

<template>
  <div class="main-wrap">
    <div class="main-inner-base">
      <div class="main-left-box">
        <div class="essay-content">
           <Carousel @update-theme="updateTheme"/>
        </div>
      </div>

      <div class="main-right-box">
        <div class="main-content">
          <div class="main-mid-box">
            <div class="current-box">
              <Watch />
            </div>

            <div class="link-box">
              <button class="a-link" @click="onClickBoardButton({boardName: 'essay'})">
                낭만 에세이
              </button>
              <div class="line-btm" />
              <button class="a-link" @click="onClickBoardButton({boardName: 'inquiry'})">
                1:1문의
              </button>
            </div>
          </div>

          <div class="news-box">
            <h3 class="tit-h3">
              새소식
            </h3>
            <div class="news-list-box">
              <div
                class="data-item"
                v-for="item in newsData"
                :key="item.id"
              >
                <span @click="onClickArticle({articleId: item.articleId, boardTitle: item.boardTtl})">
                  {{ item.articleTtl }}
                </span>
                <i class="i-news">N</i>
              </div>
            </div>
          </div>

          <div class="calendar">
            <Calendar height="440px"/>
          </div>

          <div class="quick-menu-box">
            <ComLatest :pr-pgmode="pgmode" :pr-data="rsDataAll" @printclick="printManse" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.header-wrap {
  background-color: transparent;
  .inner-base.pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.main-wrap {
  .content-holiday {
    top: auto !important;
  }
  .content-season {
    top: auto !important;
  }

  .data-item span {
    cursor: pointer;
  }
}
</style>