<script setup>
import Inspire from '@/components/Inspire.vue';

definePage({ meta: { layout: 'empty' } })
</script>

<template>
  <Inspire />
</template>

<style lang="scss">
@use "@nstyles/temporary.scss";
</style>