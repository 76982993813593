<script setup>
import FullCalendar from '@fullcalendar/vue3'
import moment from 'moment';
import { useCalendar } from '@/composables/useCalendar';
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue';
import ComLatest from '@/components/manse/ComLatest.vue';
import { onMounted } from 'vue';
import { calendarFilter } from '@/api/EowayApi';
import { DEV_MODE } from '@/main';

definePage({ meta: { layout: "empty" } });

const isLoading = ref(false);
const focus = ref('');
const type = ref('month');
const typeToLabel = reactive({
  month: 'Month',
  week: 'Week',
  day: 'Day',
  '4day': '4 Days',
});
const selectedEvent = reactive({});
const selectedElement = ref(null);
const selectedOpen = ref(false);
const events = ref([]);
// const selectedDate = ref(new Date());
const todayDate = ref(moment().format('YYYY-MM-DD'));
const curMonthData = ref(null);
const curMonthDataArray = ref([]);
const customFormat = ref('MMMM D');
const dayData = ref(null);
const disChnFlag = ref(true);

/** 달력 필터 - 음력 선택 여부 */
const isLunarChecked = ref(true);
/** 달력 필터 - 절기 선택 여부 */
const isSeasonChecked = ref(true);
/** 달력 필터 - 공휴일 선택 여부 */
const isHolidayChecked = ref(true);
/** 달력 필터 - 한글표기 선택 여부 */
const isKorChecked = ref(false);

/** DatePicker 첫 표시 여부 */
const datePickerHasUpdated = ref(false)
/** DatePicker ViewMode 상태값 */
const viewMode = ref('months')
/** DatePicker 표시 상태값 */
const showPicker = ref(false);
/** DatePicker 선택된 날짜 */
const selectedPickerDate = ref(null);

/** 마우스 드래그 시작 X좌표 */
const startX = ref(0);
/** 마우스 드래그 X좌표 이동 거리 */
const translateX = ref(0);
/** 마우스 드래그 동작 상태 */
const isDragging = ref(false);
/** 모바일 터치 시작 X좌표 */
const touchStartX = ref(0);
/** 모바일 터치 시작 Y좌표 */
const touchStartY = ref(0);
const screenWidth = ref(window.innerWidth);
const localeOptions = reactive({
  firstDayOfWeek: 1, // Starts on Monday
  masks: {
    L: 'YYYY년 MM월', // Format in Korean
    l: 'YYYY-MM', // Default format
  },
});
const isPopSajuClock = ref(false);

const curYear = ref(new Date().getFullYear());
const curMonth = ref(new Date().getMonth() + 1); // JavaScript의 월은 0부터 시작하므로 +1
const currentClockRef = ref(null)

const {
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle
} = useCalendar({
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  deviceType: 'mobile'
})

const getUserFilter = async (userId) => {
  try {
    const result = await calendarFilter({id: userId})
  } catch (e) {
    DEV_MODE && console.log('schedule_app getUserFilter error:', e)
  }
}

// onMounted(() => {
  // window.addEventListener('message', (e) => {
  //     // const message = JSON.parse(e.data);
  //     // console.log('message1');
  //     console.log(e?.data);
  //     if (!e.data.userId) return
  //     getUserFilter(e?.data?.userId);
  //   });
  //   document.addEventListener('message', (e) => {
  //     // const message = JSON.parse(e.data);
  //     // console.log('message2');
  //     console.log(e?.data);
  //     if (!e.data.userId) return
  //     getUserFilter(e?.data?.userId);
  //   });
// })

// watch(selectedPickerDate, (newValue) => {
//   console.log('========================================')
//   console.log('newValue:', newValue)
//   console.log('========================================')
// })

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  selectedDate.value = moment().format('YYYY-MM-DD');
  changeViewTitle()
}
/** 사주시계 팝업  */
const setSajuClock = (state) => isPopSajuClock.value = state;

/** DatePicker 달 선택 시 동작 */
// const setFirstDayOfMonth = async (updatePickerDate) => {
//   console.log('========================================')
//   console.log('updatePickerDate:', updatePickerDate)
//   console.log('========================================')

//   if (!updatePickerDate) showPicker.value = false

//   if (!datePickerHasUpdated.value) {
//     datePickerHasUpdated.value = true
//   } else {
//     if (viewMode.value === 'year') {
//       viewMode.value = 'months'
//     } else {
//       showPicker.value = false;
//       viewMode.value = 'months'
//     }
//     // const dateParts = selectedPickerDate.split('-');
//     // const year = parseInt(dateParts[0], 10);
//     // const month = parseInt(dateParts[1], 10);
//     // const firstDayOfMonth = new Date(year, month - 1, 1);
//     // focus.value = firstDayOfMonth;
//   }
// }
// /** viewMode 제어 */
// const updateViewMode = (updateViewMode) => {
//   // 날짜선택('month')은 사용하지 않으므로 년 선택('year')으로 변경되도록 설정
//   if (updateViewMode === 'month') viewMode.value = 'year'
//   else viewMode.value = updateViewMode
// }

/** 마우스 클릭 이벤트 핸들러 - 시작 */
const handleMouseDown = (event) => {
  startX.value = event.clientX;
  isDragging.value = true;
  window.addEventListener('mousemove', handleMouseMove);
  window.addEventListener('mouseup', handleMouseUp);
}
/** 마우스 클릭 이벤트 핸들러 - 이동 */
const handleMouseMove = (event) => {
  if (!isDragging.value) return;
  const deltaX = event.clientX - startX.value;
  translateX.value += deltaX;
  startX.value = event.clientX;
}
/** 마우스 클릭 이벤트 핸들러 - 종료 */
const handleMouseUp = () => {
  if (isDragging.value) {
    isDragging.value = false;
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);

    const sliderWidth = window.innerWidth * 0.8;
    const threshold = sliderWidth / 12; // Adjust as needed

    if (Math.abs(translateX.value) > threshold) {
      if (translateX.value > 0) {
        prev(); // Swipe to the right (previous month)
      } else {
        next(); // Swipe to the left (next month)
      }
    }
    translateX.value = 0; // Reset translateX value
  }
}

/** 모바일 터치 이벤트 핸들러 - 시작 */
const handleTouchStart = (event) => {
  // 멀티 터치 이벤트인 경우, 처리 중지
  if (event.touches.length > 1) return;

  // 터치 시작 시의 좌표 저장
  touchStartX.value = event.touches[0].clientX;
  touchStartY.value = event.touches[0].clientY;
}
/** 모바일 터치 이벤트 핸들러 - 스와이프 */
const handleTouchMove = (event) => {
  // 멀티터치 이벤트인 경우, 처리 중지
  if (event.touches.length > 1) return;

  // 터치 종료 시의 좌표 저장
  const touchEndX = event.touches[0].clientX;
  // X 좌표 변화량 계산
  const deltaX = touchEndX - touchStartX.value;

  if (Math.abs(deltaX) > 50) {
    if (deltaX > 0) {
      prev(); // 왼쪽에서 오른쪽으로 스와이프
    } else {
      next(); // 오른쪽에서 왼쪽으로 스와이프
    }
  }
}

/** 통합 이벤트 핸들러 - 이벤트 시작 */
const handleStart = (event) => {
  const point = event.touches ? event.touches[0] : event;
  startX.value = point.clientX;
  isDragging.value = true;
};
/** 통합 이벤트 핸들러 - 이벤트 이동 */
const handleMove = (event) => {
  if (!isDragging.value) return;

  const point = event.touches ? event.touches[0] : event;
  const deltaX = point.clientX - startX.value;

  if (Math.abs(deltaX) > 50) {
    isDragging.value = false;
    if (deltaX > 0) {
      prev(); // 왼쪽에서 오른쪽으로 스와이프 또는 드래그 (이전 달)
    } else {
      next(); // 오른쪽에서 왼쪽으로 스와이프 또는 드래그 (다음 달)
    }
    translateX.value = 0; // 동작 후 초기화
  }
};
/** 통합 이벤트 핸들러 - 이벤트 종료 */
const handleEnd = () => {
  if (isDragging.value) {
    isDragging.value = false;
    translateX.value = 0; // 초기화
  }
};

const printManse = () => {
  alert('사주조회 후 이용하여 주시기 바랍니다.');
};
</script>

<template>
  <div class="calendar-wrap">
    <div class="content-main mb-large">
      <section class="inner-base">
        <div>
          <div class="schedule-year-box">
            <v-sheet>
              <v-toolbar flat>
                <div class="sec-date-box">
                  <div class="title-section">
                    <v-btn fab color="grey darken-2" @click="prev">
                      <VIcon
                        class="schedule-year-icon-arrow"
                        icon="ri-arrow-left-s-line"
                      />
                    </v-btn>
                    <v-toolbar-title
                      class="txt-year"
                      @click="showPicker = !showPicker"
                    >
                      {{ viewTitle }}
                    </v-toolbar-title>
                    <v-btn fab color="grey darken-2" @click="next">
                      <VIcon
                        class="schedule-year-icon-arrow"
                        icon="ri-arrow-right-s-line"
                      />
                    </v-btn>
                  </div>
                  <v-col class="sch-word">
                    {{ firstSeason }} (<span class="month">{{ firstSeasonMonth }}</span>月)
                  </v-col>
                </div>
                <v-spacer />
                <div class="sec-btn-box">
                  <button
                    v-if="!isToday()"
                    class="btn-today"
                    @click="today"
                  >
                    TODAY
                  </button>
                  <button
                    class="btn-clock"
                    @click="setSajuClock(true)"
                  >
                    사주시계
                  </button>
                </div>
              </v-toolbar>
            </v-sheet>
          </div>

          <div class="sec-sch-body">
            <v-row
              class="fill-height"
              @mousedown="handleStart"
              @mousemove="handleMove"
              @mouseup="handleEnd"
              @touchstart="handleStart"
              @touchmove="handleMove"
              @touchend="handleEnd"
              >
              <v-col>
                <v-sheet>
                  <FullCalendar
                    ref="refCalendar"
                    class="full-calendar"
                    :options="calendarOptions"
                  >
                  </FullCalendar>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      </section>
    </div>

    <div
      v-if="isLoading"
      class="loading-full-wrap"
    >
      <div class="inner-box">
        <div
          ref="loadingContainer"
          class="loading-container"
        >
          <v-progress-circular
            :size="50"
            :width="3"
            color="primary"
            indeterminate
          />
        </div>
      </div>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />
  </div>
</template>

<style lang="scss">
.calendar-wrap {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.intro-layout {
  width: 100vw;
  height: 100%;
  .main {
    padding: 100px !important;
    margin: 0 !important;
  }
}
.content-main.mb-large {
  margin: 0;
  .inner-base {
    width: 100%;
    height: 100%;
    margin: 0;
    .v-toolbar {
      width: 100%;
    }
  }
}
.content-main {
  background-color: white;
}

/** 헤더 영역 */
.schedule-year-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-sheet {
  width: 100%;
}
.v-toolbar {
  width: 100%;

  .title-section {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: row;
  }
  .v-btn {
    height: 24px;
  }
}
.v-toolbar__content {
  width: 100%;
  height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;

  .sec-date-box {
    justify-content: center;
  }

  .sec-btn-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.v-toolbar-title {
  .v-toolbar-title__placeholder {
    flex: 0;
    font-size: 16px;
    text-align: center;
  }
}
.v-toolbar__content > .v-btn:first-child {
  margin-inline-start: 0;
}
.v-toolbar__content > .v-toolbar-title {
  margin-inline-start: 0;
  flex: 0 0 auto
}

/** 절기 MM월 DD일 .. ()月 */
.sch-word {
  padding: 0;
  color: #575757;
  font-size: 14px;
  .month {
    font-size: 16px;
    font-weight: 600;
  }
}

/** 달력 영역 */
.sec-sch-body {
  width: 100%;
  height: 100%;
  /* height: calc(100vh - 64px); */

  .v-row {
    width: 100%;
    margin: 0;
    /* margin-left: 12px; */
    .v-col {
      margin: 0;
      height: 100%;
    }
  }
}
.full-calendar {
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 64px);
}
/** FullCalendar 격자 설정 */
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid var(--fc-border-color);
}
.fc-theme-standard th {
  border: 0px solid var(--fc-border-color);
}
.fc-theme-standard td {
  border: 0px solid var(--fc-border-color);
  border-top: 1px solid #d3d3d3 !important;
}
/** FullCalendar 오늘 날짜 dayCell */
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

/* .v-calendar-monthly .v-calendar-weekly__head-weekday {
  height: 38px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23252E;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 0.5px solid #E2E2E2;
} */
/* .v-calendar-weekly__day-label {
  margin: 0 !important;
} */
/* .v-calendar-weekly__day-label button.v-btn {
  display: none;
  margin:0 !important
} */
.datePicker .v-card__text {
  padding: 20px !important;
}

.fc-header-toolbar {
  margin-bottom: 0 !important;
}
.fc-day-sun {
  color: #EB4F5D;
}
.fc-day-sat {
  color: #5A6EF6;
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.fc .fc-daygrid-day-top {
  position: absolute;
  top: 4px;
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: center;
}
.fc .fc-daygrid-day-number {
  padding: 1px;
  position: relative;
  z-index: 4;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  height: 95%;
  margin: 0;
}
.fc .fc-daygrid-event-harness {
  height: 100%;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  height: 100%;
  margin: 4px 0 0 0;
}

.custom-day-cell {
  width: 20px;
  height: 20px;
  font-size: 14px;
  border-radius: 100%;
  margin: 0 auto;
  font-weight: 400;
  text-align: center;
}
.custom-day-sunday, .custom-day-holiday {
  color:#EB4F5D
}
.custom-day-saturday {
  color: #5A6EF6;
}
.custom-day-highlight {
  background-color: #EB4C10;
  color: white
}
.custom-day-selected {
  background-color: #E1E1E1;
}
.custom-event-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // flex-wrap: wrap;
}
.custom-content-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.content-dayju {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 18px;
  color: #23252E;
  font-weight: 400;
}
.txt-chn {
  font-size: 16px;
}
.txt-kor {
  font-size: 14px;
}
.content-lunar {
  color: #23252E;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0;
  right: 0;
}
.content-holiday {
  color: #EB4F5D;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: 0 !important;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}
.content-season {
  color: #23252E;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: 0 !important;
  flex-wrap: wrap;
}

.custom-overlay {
  border-radius: 4px; /* 모서리를 둥글게 할 수 있습니다 */
  box-sizing: border-box; /* padding과 border를 포함하여 크기 계산 */
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background-color: transparent;
}
.fc-daygrid-dot-event {
  padding: 0;
}

.custom-day-cell.other {
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
}

.schedule-year-icon-arrow {
  height: 16px;
  width: 16px;
  background-color: #2c2c2e
}

.txt-year {
  min-inline-size: auto;
}

.v-col-xxl, .v-col-xxl-auto, .v-col-xxl-12, .v-col-xxl-11, .v-col-xxl-10, .v-col-xxl-9, .v-col-xxl-8, .v-col-xxl-7, .v-col-xxl-6, .v-col-xxl-5, .v-col-xxl-4, .v-col-xxl-3, .v-col-xxl-2, .v-col-xxl-1, .v-col-xl, .v-col-xl-auto, .v-col-xl-12, .v-col-xl-11, .v-col-xl-10, .v-col-xl-9, .v-col-xl-8, .v-col-xl-7, .v-col-xl-6, .v-col-xl-5, .v-col-xl-4, .v-col-xl-3, .v-col-xl-2, .v-col-xl-1, .v-col-lg, .v-col-lg-auto, .v-col-lg-12, .v-col-lg-11, .v-col-lg-10, .v-col-lg-9, .v-col-lg-8, .v-col-lg-7, .v-col-lg-6, .v-col-lg-5, .v-col-lg-4, .v-col-lg-3, .v-col-lg-2, .v-col-lg-1, .v-col-md, .v-col-md-auto, .v-col-md-12, .v-col-md-11, .v-col-md-10, .v-col-md-9, .v-col-md-8, .v-col-md-7, .v-col-md-6, .v-col-md-5, .v-col-md-4, .v-col-md-3, .v-col-md-2, .v-col-md-1, .v-col-sm, .v-col-sm-auto, .v-col-sm-12, .v-col-sm-11, .v-col-sm-10, .v-col-sm-9, .v-col-sm-8, .v-col-sm-7, .v-col-sm-6, .v-col-sm-5, .v-col-sm-4, .v-col-sm-3, .v-col-sm-2, .v-col-sm-1, .v-col, .v-col-auto, .v-col-12, .v-col-11, .v-col-10, .v-col-9, .v-col-8, .v-col-7, .v-col-6, .v-col-5, .v-col-4, .v-col-3, .v-col-2, .v-col-1 {
  width: 100%;
  padding: 0px;
}
table tbody tr{
  height: 3px
}
</style>

<style lang="scss" scoped>
.v-toolbar {
  background-color: #fff;
}
</style>

<style lang="scss">
@media (max-width: 767px) {
  body {
    max-width: 100%;
  }
}
</style>