<!-- src/layouts/MypageLayout.vue -->
<script setup>
import { useRouter } from "vue-router";
import HeaderComponent from "@/layouts/Header.vue";
import FooterComponent from "@/layouts/Footer.vue";

const router = useRouter();
function goLink(path) {
  router.push(path);
}
</script>
<template>
  <div class="manse-layout">
    <header>
      <HeaderComponent />
    </header>
    <main>
      <RouterView />
    </main>
    <footer>
      <FooterComponent />
    </footer>
  </div>
</template>


