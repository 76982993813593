<script setup>
import fiveMixin from '@/composables/fiveMixin';
import useFunctions from '@/composables/useFunctions';

const { ...five } = fiveMixin();
const { ...useFun } = useFunctions();
defineProps({
  prBirthData: {
    type: Object,
    default: null,
  },
  prBirthJijanggan: {
    type: Object,
    default: () => ({}),
  },
  prLangMode: {
    type: String,
    default: '',
  },
  prBirthTitle: {
    type: String,
    default: '',
  },
  prType: {
    type: String,
    default: '',
  },
  prCurDaeun: {
    type: Object,
    default: () => ({}),
  },
  prTransdata: {
    type: Object,
    default: () => ({}),
  },
})

const localCurDaeun = ref(null);

onMounted( () => {
  //setCurDaeun(prCurDaeun);
});

const setCurDaeun = (curDaeun) => {
  localCurDaeun.value = curDaeun;
}
function getCurDaeunNum(data) {
  if (data) {
    return data[0]?.num;
  }
  return '';
}

function getYearSix(data, pos) {
  let txt = '';
  const posEng = getPosEng(pos);
  txt = data?.[posEng]?.six;
  return txt;
}
function getPosEng(pos) {
  let txt = '';
  if (pos === '년') txt = 'year';
  if (pos === '월') txt = 'month';
  if (pos === '일') txt = 'day';
  if (pos === '시') txt = 'hour';
  return txt;
}
function getValueTxt(data, pos, val) {
  let txt = '';
  const posEng = getPosEng(pos);
  txt = data?.[posEng]?.[val];
  if (val === 'sal') {
    txt = txt ? txt[0] : '';
  }
  return txt;
}
function getValueTxtOne(data, pos, val, index) {
  let txt = '';
  const posEng = getPosEng(pos);
  txt = data?.[posEng]?.[val]?.[index];
  return txt;
}
function getYearSixD(data, pos) {
  let txt = '';
  if (data && data.length > 0) {
    txt = data?.[pos][0]?.six;
  }
  return txt;
}
function getValueTxtD(data, val) {
  let txt = '';
  if (data && data.length > 0) {
    txt = data[0]?.[val];
    if (val === 'sal') {
      txt = txt ? txt[0] : '';
    }
  }
  return txt;
}
function getValueTxtDOne(data, val, index) {
  let txt = '';
  if (data && data.length > 0) {
    txt = data[0]?.[val]?.[index];
  }
  return txt;
}

</script>

<template>
  <div class="data-0101-box">
    <div
      class="birth-tit-box"
    >
      <li class="row01 row-box">
        <span>십성</span>
      </li>
      <li
        class="row02 row-box"
      >
        <span>천간</span>
      </li>
      <li class="row03 row-box">
        <span>지지</span>
      </li>
      <li class="row04 row-box">
        <span>십성</span>
      </li>
      <li class="row05 row-box">
        <span>십이운성</span>
      </li>
      <li class="row06 row-box">
        <span>십이신살</span>
      </li>
      <li class="row07 row-box">
        <span>지장간</span>
      </li>
    </div>
    <ul
      class="birth-item-box birth bg-line txt-big"
    >
      <li>
        <span
          v-if="getCurDaeunNum(prCurDaeun.sky) === prTransdata?.curlucknum"
          class="txt1"
        >
          현재대운
        </span>
        <span
          v-else
          class="txt1"
        >
          {{ getCurDaeunNum(prCurDaeun.sky) }} 대운
        </span>
      </li>
      <li><span class="txts">{{ getValueTxtD(prCurDaeun.sky, 'six') }}</span></li>
      <li>
        <span
          class="txt-round xl"
          :class="[five.getColorFive(getValueTxtD(prCurDaeun.sky, 'value')), prLangMode === 'CHNKOR' ? 'chnkor' : '', prLangMode]"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(getValueTxtD(prCurDaeun.sky, 'value'), 'sky') }} </em>
          <em class="txt-main">{{ getValueTxtD(prCurDaeun.sky, 'value') }}</em>
        </span>
      </li>
      <li>
        <span
          class="txt-round xl mb6"
          :class="[five.getColorFive(getValueTxtD(prCurDaeun.earth, 'value')), prLangMode === 'CHNKOR' ? 'chnkor' : '', prLangMode]"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(getValueTxtD(prCurDaeun.earth, 'value'), 'earth') }} </em>
          <em class="txt-main">{{ getValueTxtD(prCurDaeun.earth, 'value') }}</em>
        </span>
      </li>
      <li><span class="txts">{{ getValueTxtD(prCurDaeun.earth, 'six') }}</span></li>
      <li><span class="txts txt-earth-02">{{ getValueTxtD(prCurDaeun.earth, 'twelve') }}</span></li>
      <li><span class="txts txt-earth-02">{{ getValueTxtD(prCurDaeun.earth, 'sal') }}</span></li>
      <!-- <li

        class="jijangan st-row"
      >
        <span class="txt-xs">
          <span
            v-for="(item, index2) in getValueTxtD(prCurDaeun.earth, 'jijanggan')"
            :key="index2"
          >
            <em>{{ item }}</em>
          </span>
        </span>
      </li> -->
      <li

        class="jijangan"
      >
        <span class="txt-group-root">
          <span
            v-for="(item, index2) in getValueTxtD(prCurDaeun.earth, 'jijanggansix') "
            :key="index2"
            class="txt-group"
          >
            <em
              class="txt-big ganji-txt"
              :class="[five.getColorFive(getValueTxtDOne(prCurDaeun.earth, 'jijanggan', index2)), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
            >{{ getValueTxtDOne(prCurDaeun.earth, 'jijanggan', index2) }}</em> <em class="txt-small">{{ item }}</em>
          </span>
        </span>
      </li>
    </ul>
    <ul
      v-for="(char, index) in prBirthTitle"
      :key="index"
      class="birth-item-box birth txt-big"
    >
      <li>
        <span class="txt1">{{ char }}</span>
      </li>
      <li><span class="txts">{{ getYearSix(prBirthData?.sky, char ) }}</span></li>
      <li>
        <span
          class="txt-round xl"
          :class="[five.getColorFive(getValueTxt(prBirthData?.sky, char, 'value' )), prLangMode === 'CHNKOR' ? 'chnkor' : '', prLangMode]"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(getValueTxt(prBirthData?.sky, char, 'value' ), 'sky') }} </em>
          <em class="txt-main">{{ getValueTxt(prBirthData?.sky, char, 'value' ) }}</em>
        </span>
      </li>
      <li>
        <span
          class="txt-round xl mb6"
          :class="[five.getColorFive(getValueTxt(prBirthData?.earth, char, 'value' )), prLangMode === 'CHNKOR' ? 'chnkor' : '', prLangMode]"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(getValueTxt(prBirthData?.earth, char, 'value' ), 'earth') }} </em>
          <em class="txt-main">{{ getValueTxt(prBirthData?.earth, char, 'value' ) }}</em>
        </span>
      </li>
      <li><span class="txts">{{ getYearSix(prBirthData?.earth, char ) }}</span></li>
      <li><span class="txts txt-earth-02">{{ getValueTxt(prBirthData?.earth, char, 'twelve' ) }}</span></li>
      <li><span class="txts txt-earth-02">{{ getValueTxt(prBirthData?.earth, char, 'sal' ) }}</span></li>
      <!-- <li

        class="jijangan"
      >
        <span class="txt-xs">
          <span
            v-for="(item, index2) in getValueTxt(prBirthJijanggan, char, 'jiji')"
            :key="index2"
          >
            <em>{{ item }}</em>
          </span>
        </span>
      </li> -->
      <li

        class="jijangan"
      >
        <span class="txt-xs txt-group-root">
          <span
            v-for="(item, index2) in getValueTxt(prBirthJijanggan, char, 'six')"
            :key="index2"
            class="txt-group"
          >
            <em
              class="txt-big ganji-txt"
              :class="[five.getColorFive(getValueTxtOne(prBirthJijanggan, char, 'jiji', index2)), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
            >{{ getValueTxtOne(prBirthJijanggan, char, 'jiji', index2) }}</em>
            <em class="txt-small">{{ item }}</em>
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>