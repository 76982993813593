<template>
  <div class="footer-wrap">
    <div class="inner-base">
      <section class="address-sec">
        <div class="ft-logo">
          <i class="logo">낭만 만세력</i>
        </div>
        <div class="link-area">
          <ul>
            <li>
              <nuxt-link to="/"> 서비스 소개 </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/"> 이용약관 </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/"> 개인정보처리방침 </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/"> 이용안내 </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/"> 고객센터 </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="info-area">
          <ul>
            <li>
              <em>주소</em> 경기도 수원시 영통구 신원로 55, 테크트리 영통
              지식산업센터 709호, 710호
            </li>
            <li><em>사업자등록번호 </em>303-81-79157</li>
            <li><em>메일 </em>info@dangamsoft.com</li>
            <li><em>문의 </em>1670-0256</li>
          </ul>
        </div>
        <div class="copyright">Copyright ⓒ DANGAMSOFT All Right Reserved</div>
      </section>
    </div>
  </div>
</template>
