<script setup>
  import { onMounted } from 'vue';
  import fiveMixin from '@/composables/fiveMixin';
  import useFunctions from '@/composables/useFunctions';
  const { ...five } = fiveMixin();
  const { ...useFun } = useFunctions();

  const emit = defineEmits();

  const props =defineProps({
    prData: {
      type: Object,
      default: null,
    },
  })
  const PopShareBox = ref(true);
  const PopSharePreviewBox = ref(false);
  const close = () => {
    emit('close');
  }
  const closePreview = () => {
    PopSharePreviewBox.value = false;
  };

  const openSharePreview = () => {
    PopSharePreviewBox.value = true;
  };

  const KKOID = import.meta.env.VITE_KAKAO_APP_KEY;
  /* global Kakao */
  onMounted(() => {
    // 카카오 SDK 초기화
    if (typeof Kakao !== 'undefined') {
      if (!Kakao.isInitialized()) {
        Kakao.init(KKOID);
      }
    } else {
      console.error('Kakao SDK가 로드되지 않았습니다.');
    }
  });
  // 복사 기능
  const goCopy = () => {
    const shareData = useFun.convertShareTxt(props.prData);
    navigator.clipboard.writeText(shareData)
      .then(() => {
        alert('복사 되었습니다.');
      })
      .catch((error) => {
        alert(error);
      });
  };

  // 카카오 공유 기능
  const goKko = () => {
    const shareData = useFun.convertShareTxt(props.prData);
    const frontUrl = import.meta.env.VITE_WEB_BASE_URL;
    if (typeof Kakao !== 'undefined' && Kakao.isInitialized()) {
      Kakao.Share.sendDefault({
        objectType: 'text',
        text: shareData,
        link: {
          mobileWebUrl: frontUrl,
          webUrl: frontUrl,
        },
      });
    } else {
      console.error('Kakao SDK가 로드되지 않았습니다.');
    }
  };
</script>
<template>
  <VDialog
    v-model="PopShareBox"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
  >
    <VCard >
      <div
        class="pop-inner"
        style="height: 372px;"
      >
        <div class="pop-tit-box">
          <div class="pop-tit-sub">
            사주정보 공유
          </div>
          <DialogCloseBtn
            variant="text"
            size="default"
            @click="close"
          />
        </div>
        <div class="pop-body pd-large">
          <div class="pop-exp">
            사주정보 결과를 복사하고, 공유 할 수 있는 기능입니다.
          </div>
          <div class="share-com-btn-box">
            <div
              class=""
              @click="goCopy()"
            >
              <div class="icon-box">
                <i class="icon-message">붙여넣기아이콘</i>
              </div>
              <div class="txt1">
                텍스트 복사
              </div>
              <div class="txt2">
                원하는 곳에 붙여넣기<br>(Ctrl+V) 하여 사용하세요.
              </div>
            </div>
            <div
              class=""
              @click="goKko()"
            >
              <div class="icon-box">
                <i class="icon-kko">카카오톡아이콘</i>
              </div>
              <div class="txt1">
                카카오톡 공유
              </div>
              <div class="txt2">
                카카오톡 친구&지인에게 <br>공유해보세요.
              </div>
            </div>
          </div>

          <div class="btn-guide-box">
            <button @click="openSharePreview()">
              <i class="icon-circle" /><span>공유 방법이 궁금하다면? 공유 내용 미리보기</span>
            </button>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
  <VDialog
    v-model="PopSharePreviewBox"
    class="v-dialog-sm dialog-st1"
    max-width="640px"
  >
    <VCard >
      <div
        class="pop-inner"
        style="height: 618px;"
      >
        <div class="pop-tit-box">
          <div class="pop-tit-sub">
            공유 내용 미리보기
          </div>
          <DialogCloseBtn
            variant="text"
            size="default"
            @click="closePreview"
          />
        </div>
        <div class="pop-body">
          <div class="share-preview-box">
            <div class="img01" />
            <div class="img02" />
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>