<script setup>
import useMenuLinks from "@/composables/useMenuLinks";
import useGoLink from "@/composables/useGoLink";
import useAuth from "@/composables/useAuth";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { computed } from 'vue';
import { useRoute } from 'vue-router';

import GnbMypageMenu from "@/components/GnbMypageMenu.vue"

const route = useRoute();
const currentPath = computed(() => route.path);

const props = defineProps({
  iconTheme: {
    type: String,
    default: null
  }
})

const { menuLinks } = useMenuLinks();
const { goLink } = useGoLink();
const { userInfo } = useAuth();
const store = useStore();
const router = useRouter();

const hoverLayer = ref('');

const logOut = async () => {
  if (!window.confirm("로그아웃 하시겠습니까?")) {
    return;
  }

  try {
    await store.dispatch("user/logout");
    alert("로그아웃 되었습니다.");
    await router.push("/auth/login");
  } catch (error) {
    alert(error.message);
  }
};
const goLogout = () => {
  if (hoverLayer.value === 'logout') {
    hoverLayer.value = '';
  } else {
    hoverLayer.value = 'logout';
  }
}
const goLogin = () => {
  if (hoverLayer.value === 'login') {
    hoverLayer.value = '';
  } else {
    hoverLayer.value = 'login';
  }
}
const login = () => {
  router.push("/auth/login");
}
const goLinkDo = (pathUrl) => {
  router.push(pathUrl);
}

const goMain = () => router.push('/main')
/** iconTheme 에 따라 로고 변경 */
const logoClass = computed(() => (props.iconTheme === 'WHITE' ? 'hd__logo_w' : 'hd__logo_b'));
</script>

<template>
  <div class="header-wrap">
    <div class="inner-base pc">
      <h1 :class="['hd__logo', logoClass]" @click="goMain">만세력</h1>
      <div class="nav">
        <ul class="nav-list">

          <li v-for="(menu, key) in menuLinks" :key="key" :class="{ 'active': currentPath === menu.link }">
            <router-link :to="menu.link">
              {{ menu.name }}
            </router-link>
            <!-- <ul class="sub-menu" v-if="menu.subMenu.length > 0">
              <li v-for="(subMenu, subKey) in menu.subMenu" :key="subKey">
                <a
                  :href="subMenu.link"
                  @click.prevent="goLink(subMenu.link)"
                  >{{ subMenu.name }}</a
                >
              </li>
            </ul> -->
          </li>
          <li>

            <button
              v-if="userInfo?.name"
              @click="goLogout()"
            >
              <i class="icon-mypage">로그아웃</i>
            </button>
            <button
              v-else
              :class="{ 'active': currentPath === '/login' }"
              @click="goLogin()"
            >
              <i class="icon-mypage">로그인</i>
            </button>
            <div
              v-if="hoverLayer ==='logout'"
              class=""
            >
              <div class="login-msg-box">
                <div class="txt-box">
                  <span><span class="txt1">{{ userInfo?.name }}</span>님</span><span class="txt2">행복한 하루 되세요</span>
                </div>
                <div class="point-box st-member">
                  <i class="icon-point"></i> <span>0 P</span>
                </div>
                <GnbMypageMenu />
                <button
                  class="btn-xs line"
                  @click="logOut"
                >
                  로그아웃
                </button>
              </div>
            </div>
            <div
              v-if="hoverLayer ==='login'"
              class=""
            >
              <div class="login-msg-box">
                <div class="txt-box">
                  <span><span class="txt1">게스트</span>님</span> <span class="txt2">행복한 하루 되세요</span>
                </div>
                <div class="btn-box">
                  <button
                    class="btn-xs"
                    @click="login"
                  >
                    로그인
                  </button>
                  <button
                    class="btn-xs line"
                    @click="join"
                  >
                    회원가입
                  </button>
                </div>
                <div class="point-box">
                  <i class="icon-point"></i> <span>회원가입 시, <br>낭만 포인트 100% 지급!</span>
                </div>
                <GnbMypageMenu />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@use "@nstyles/manse.scss";
</style>
