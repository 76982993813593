<script setup>
import { useRoute } from "vue-router";
import {IconNotice, IconEssay, IconEvent, IconFaq, IconInquiry, IconCounseling} from "@/components/svg"

const route = useRoute();

const menuTitle = "낭만센터"
const gnbMenuItems = [
  { name: "공지사항", link: "/customer/notice", icon: "icon-notice" },
  { name: "이벤트", link: "/customer/event", icon: "icon-event" },
  { name: "낭만 에세이", link: "/customer/essay", icon: "icon-essay" },
  { name: "자주묻는질문", link: "/customer/faq", icon: "icon-faq" },
  { name: "1:1 문의", link: "/customer/inquiry", icon: "icon-inquiry" },
  { name: "명리상담", link: "/customer/counseling", icon: "icon-counseling" },
]

const isActive = (menuLink) => {
  // 현재 경로(route.fullPath)가 menuLink로 시작하는지 확인
  return route.path.startsWith(menuLink);
};
</script>

<template>
  <div class="gnb-wrap">
    <h3>{{ menuTitle }}</h3>
    <div class="gnb-menu-box">
      <div v-for="(menu, index) in gnbMenuItems" :key="index">
        <RouterLink 
          class="gnb-link"
          :class="{ active: isActive(menu.link) }"
          active-class="active"
          :to="menu.link" 
        >
          <IconNotice v-if="menu.icon === 'icon-notice'" />
          <IconEvent v-if="menu.icon === 'icon-event'" />
          <IconEssay v-if="menu.icon === 'icon-essay'" />
          <IconCounseling v-if="menu.icon === 'icon-counseling'" />
          <IconFaq v-if="menu.icon === 'icon-faq'" />
          <IconInquiry v-if="menu.icon === 'icon-inquiry'" />

          {{ menu.name }}
        </RouterLink>
      </div>
    </div>
    <div class="info-box">
      <div class="txt-tit">고객센터</div>
      <div class="txt-tel">02-1670-0256</div>
      <div class="txt-exp">
        평일 13:00 - 18:00 (주말, 공휴일 휴무)<br />
        답변 시간 이후 접수 건은 운영시간 내 <br />
        순차적으로 답변해 드립니다.
      </div>
    </div>
  </div>
</template>
