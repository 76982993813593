import moment from 'moment';
import 'moment/locale/ko';
import _ from 'lodash';
import dayjs from 'dayjs';

export default function useFunctions() {
  const truncateString = (str, length) => {
    if (typeof str === 'string' && str.length > length) {
      return str.slice(0, length);
    }
    return str;
  };

  const getGenderTxt = (txt) => {
    let rsTxt = '';
    if (txt === 'WOMEN' || txt === 0 || txt === '0') {
      rsTxt = '여';
    }
    if (txt === 'MEN' || txt === 1 || txt === '1') {
      rsTxt = '남';
    }
    return rsTxt;
  }

  const getGenderTxt3 = (txt) => {
    let rsTxt = '';
    if (txt === 0 || txt === '0') {
      rsTxt = 'WOMEN';
    }
    if (txt === 1 || txt === '1') {
      rsTxt = 'MEN';
    }
    return rsTxt;
  }
  const getGroupTxt = (str) => {
    let rsTxt = '';
    if (str === null) {
      rsTxt = '미지정';
    } else {
      rsTxt = str?.name;
    }
    return rsTxt;
  }
  const getDateTxt = (dateString) => {
    if (!dateString) {
      return '미지정';
    }

    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}.${(`0${date.getMonth() + 1}`).slice(-2)}.${(`0${date.getDate()}`).slice(-2)}`;
    return formattedDate;
  }
  const formatTxtLunarString = (dateString) => {
    if (dateString?.trim() !== '') {
      const [, month, day] = dateString.split('-');
      return `${month}/${day}`;
    }
    return '';
  }

  const getDateTxtString = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return `${year}.${month.padStart(2, '0')}.${day.padStart(2, '0')}`;
    }
    return '';
  }

  const getDateTxtString_2 = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return `${year.slice(-2)}/${month.padStart(2, '0')}/${day.padStart(2, '0')}`;
    }
    return '';
  }

  const getUserNameTxt = (str) => str ?? '-';

  const formatDateHH = (date) => {
    return  moment(date).format('YYYY.MM.DD HH:mm');
  };

  const formatDateHHDay = (date) => {
    return moment(date).format('YYYY년 MM월 DD일 HH:mm (ddd요일)');
  };

  const formatCustomDate = (dateString) => {
    return moment(dateString).format('YYYY.MM.DD');
  };

  const formatCustomDateYYYY = (dateString) => {
    return moment(dateString).format('YYYY');
  };

  const formatCustomDateMM = (dateString) => {
    return moment(dateString).format('MM');
  };

  const formatCustomDateDD = (dateString) => {
    return moment(dateString).format('DD');
  };

  const formatCustomDateHH = (dateString) => {
    return moment(dateString).format('HH:mm');
  };
  const getDayOfWeek = (year, month, day) => {
    const daysOfWeek = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    const date = new Date(`${year}-${month}-${day}`);
    const dayOfWeekIndex = date.getDay(); // 0(일)부터 6(토)까지의 숫자를 반환
    return daysOfWeek[dayOfWeekIndex];
  };

  const getDayOfWeekEng = (data) => {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const date = new Date(`${data}`);
    const dayOfWeekIndex = date.getDay(); // 0(일)부터 6(토)까지의 숫자를 반환
    return daysOfWeek[dayOfWeekIndex];
  };

  const getDayOfWeekOne = (val) => {
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
    const date = new Date(val);
    const dayOfWeekIndex = date.getDay(); // 0(일)부터 6(토)까지의 숫자를 반환
    return daysOfWeek[dayOfWeekIndex];
  };

  const getTimeTxt = (time) => {
    const timeParts = time.split(':'); // 시간을 ":" 기준으로 나눔
    const hour = timeParts[0].padStart(2, '0'); // 시간부분을 2자리로 만듦
    const minute = timeParts[1].padStart(2, '0'); // 분부분을 2자리로 만듦
    return `${hour}시 ${minute}분`; // 시간과 분을 문자열로 반환
  };

  const getTimeTxt2 = (time) => {
    time = String(time);
    return time?.padStart(2, '0');
  };

  const getBirthTimeTxt = (str) => {
    return str === '25:00' ? '--:--' : str;
  };
  const formatOption1Txt = (str) => {
    if (str) {
      return '야자시/조자시,';
    }
    return '';
  };

  const formatOption4Txt = (str) => {
    if (str) {
      return '한국시,';
    }
    return '';
  };

  const formatOption2Txt = (str) => {
    if (str === 'none') {
      return '모름,';
    }
    if (str) {
      return '동지';
    }
    return '입춘';
  };

  const handleNextInput = (value, nextRefName, limit) => {
    if (value.length === limit) {

      const nextInput = inputRefs[nextRefName].value;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }


  const convertGongmangTaewol = (args) => {
    const { gongmang, taewon } = args
    let value = ""
    let gongmangs = [...args.gongmang]
    let taewons = [...args.taewon]

    if (!gongmang || !taewon) return ""

    gongmangs = gongmang.map((item) => item.value)
    taewons = taewon.map((item) => item.value).join("")

    value = `${gongmangs.join("ㆍ")} / ${taewons}`

    return value
  }

  const getAge = (birthdate) => {
    const today = moment()
    const birth = moment(birthdate)
    const years = today.diff(birth, "years")
    return years
  }

  const getYear = (birthdate) => {
    const dateStringArry = birthdate.split("-")
    return dateStringArry[0]
  }

  const getFirstWord = (text) => {
    return text ? text.split(" ")[1] : ""
  }

  const checkDateTimeValid = (args) => {
    const { checkType } = args

    if (checkType === "date") {
      const { inputYear, inputMonth, inputDay } = args
      const _inputYear = parseInt(inputYear, 10)
      const _inputMonth = parseInt(inputMonth, 10)
      const _inputDay = parseInt(inputDay, 10)

      if (_inputYear < 1900 || _inputYear > 2100) return false
      if (_inputMonth < 1 || _inputMonth > 12) return false

      const monthMaxDate = dayjs()
        .set("year", _inputYear)
        .set("month", _inputMonth - 1)
        .set("date", 1)
        .endOf("month")
        .get("date")

      if (_inputDay < 1 || _inputDay > monthMaxDate) return false
      return true
    }
    const { inputHour, inputMinute } = args
    const _inputHour = parseInt(inputHour, 10)
    const _inputMinute = parseInt(inputMinute, 10)
    if (_inputHour < 0 || _inputHour >= 24) return false
    if (_inputMinute < 0 || _inputMinute >= 60) return false
    return true
  }

  const convertShareTxt = (data) => {
    const { inputdata, transdata, birthganji, twelve, jijanggan, daeuns, gongmang } = data
    let shareTxt = "[낭만만세력]사주결과 공유"

    if (inputdata && transdata) {
      shareTxt += convertUserBasicInfo(inputdata, transdata)
      shareTxt += convertBirthInfo(inputdata, transdata)
      shareTxt += "\n"
      shareTxt += convertUserSelectInfo(inputdata)
    }
    if (birthganji) {
      shareTxt += convertGanjiInfo(birthganji)
      shareTxt += convertGanjiKorInfo(birthganji)
    }
    if (twelve) {
      shareTxt += convertTwelveInfo(twelve)
    }
    if (birthganji) {
      shareTxt += convertSinsalInfo(birthganji)
    }
    if (jijanggan) {
      shareTxt += convertJijangganInfo(jijanggan)
    }
    if (gongmang) {
      shareTxt += convertGongmangInfo(gongmang)
    }
    if (daeuns) {
      shareTxt += convertDaeunInfo(daeuns)
    }
    return shareTxt
  }

  const convertUserBasicInfo = (inputdata, transdata) => {
    let resultTxt = ""
    const { name } = inputdata
    const gender = inputdata.gender === "1" ? "남" : "여"
    const { age } = transdata

    resultTxt += "\n\n"
    resultTxt += `${name}(${gender} ${age}세) `

    return resultTxt
  }

  const convertBirthInfo = (inputdata, transdata) => {
    const { lunsol, hour, min } = inputdata
    const { lunar, solar } = transdata
    let birthDate = ""
    let birthTime = ""
    let birthType = ""

    if (hour === 25) {
      birthTime = "25:00"
    } else {
      birthTime = `${hour < 10 ? "0" : ""}${hour}:${min < 10 ? "0" : ""}${min}`
    }
    if (lunsol === "음력") {
      birthDate = lunar
      birthType = "음"
    } else {
      birthDate = solar
      birthType = "양"
    }

    return `${birthDate}(${birthType}) ${birthTime}`
  }

  const convertUserSelectInfo = (inputdata) => {
    const { koreantime, dongji, earlytime } = inputdata
    const printDatas = []

    if (koreantime) printDatas.push("한국시")
    else printDatas.push("동경시")

    if (earlytime) printDatas.push("야자시/조자시")

    if (dongji) printDatas.push("동지")
    else printDatas.push("입춘")

    return printDatas.join("/")
  }

  const convertGanjiInfo = (birthganji) => {
    const { sky, earth } = birthganji
    let resultTxt = "\n"
    resultTxt += "\n시 일 월 년"
    resultTxt += `\n${sky.hour.value} ${sky.day.value} ${sky.month.value} ${sky.year.value}`
    resultTxt += `\n${earth.hour.value} ${earth.day.value} ${earth.month.value} ${earth.year.value}`

    return resultTxt
  }

  const convertGanjiKorInfo = (birthganji) => {
    const { sky, earth } = birthganji
    let resultTxt = "\n\n십성"
    resultTxt += `\n${sky.hour.six} ${sky.day.six} ${sky.month.six} ${sky.year.six}`
    resultTxt += `\n${earth.hour.six} ${earth.day.six} ${earth.month.six} ${earth.year.six}`

    return resultTxt
  }

  const convertTwelveInfo = (twelve) => {
    const { hour, day, month, year } = twelve
    let resultTxt = "\n\n십이운성"
    resultTxt += `\n${hour ?? ""} ${day ?? ""} ${month ?? ""} ${year ?? ""}`

    return resultTxt
  }

  const convertSinsalInfo = (birthganji) => {
    let resultTxt = "\n\n십이신살\n"
    const { hour, day, month, year } = birthganji.earth
    if (hour.sal && hour.sal.length > 0) {
      resultTxt += `${hour.sal[0]} `
    } else resultTxt += "  "

    if (day.sal && day.sal.length > 0) {
      resultTxt += `${day.sal[0]} `
    } else resultTxt += "  "

    if (month.sal && month.sal.length > 0) {
      resultTxt += `${month.sal[0]} `
    } else resultTxt += "  "

    if (year.sal && year.sal.length > 0) {
      resultTxt += `${year.sal[0]} `
    } else resultTxt += "  "

    return resultTxt
  }

  const convertJijangganInfo = (jijanggan) => {
    const { hour, day, month, year } = jijanggan
    let resultTxt = "\n\n지장간"
    const maxLength = Math.max(hour.jiji.length, day.jiji.length, month.jiji.length, year.jiji.length)

    for (let i = 0; i < maxLength; i += 1) {
      if (i === 0) {
        resultTxt += `\n${hour.jiji[i] ?? "--"} ${day.jiji[i] ?? "--"} ${month.jiji[i] ?? "--"} ${
          year.jiji[i] ?? "--"
        }`
      } else {
        resultTxt += `\n${hour.jiji[i] ?? "--"} ${day.jiji[i] ?? "--"} ${month.jiji[i] ?? "--"} ${
          year.jiji[i] ?? "--"
        }`
      }
    }

    return resultTxt
  }

  const convertGongmangInfo = (gongmang) => {
    let resultTxt = "\n\n공망\n"

    const convertData = {}
    for (const item of gongmang) {
      const { pos } = item
      convertData[pos] = item
    }

    const { hour, day, month, year } = convertData

    if (hour) resultTxt += `${hour.value}`
    if (day) resultTxt += `${day.value}`
    if (month) resultTxt += `${month.value}`
    if (year) resultTxt += `${year.value}`

    return resultTxt
  }

  const convertDaeunInfo = (daeuns) => {
    const { sky, earth } = daeuns
    let resultTxt = "\n\n대운"
    resultTxt += "\n"

    for (let i = sky.length - 1; i >= 0; i -= 1) {
      resultTxt += `${sky[i].num} `
    }
    resultTxt += "\n"
    for (let i = sky.length - 1; i >= 0; i -= 1) {
      resultTxt += `${sky[i].value} `
    }
    resultTxt += "\n"
    for (let i = earth.length - 1; i >= 0; i -= 1) {
      resultTxt += `${earth[i].value} `
    }

    return resultTxt
  }

  return { truncateString, getGenderTxt, getGenderTxt3, getGroupTxt, getDateTxt, formatTxtLunarString, getDateTxtString, getDateTxtString_2, getUserNameTxt, formatDateHH,formatDateHHDay, formatCustomDate, formatCustomDateYYYY, formatCustomDateMM, formatCustomDateDD, formatCustomDateHH, getDayOfWeek, getDayOfWeekEng, getDayOfWeekOne, getTimeTxt, getTimeTxt2, getBirthTimeTxt, formatOption1Txt, formatOption4Txt, formatOption2Txt, handleNextInput, convertGongmangTaewol, getAge, getYear, getFirstWord, checkDateTimeValid, convertShareTxt  };
}
