import moment from "moment"
import "moment/locale/ko"
import _ from "lodash"
import dayjs from "dayjs"

moment.locale("ko")

/** 요일 영어 -> 한글 변환 */
export const replaceDays = (text) => {
  const daysTable = {
    "Mon": "월",
    "Tue": "화",
    "Wed": "수",
    "Thu": "목",
    "Fri": "금",
    "Sat": "토",
    "Sun": "일"
  }

  Object.keys(daysTable).forEach((engDay) => {
    const regex = new RegExp(engDay, 'g'); // 요일을 찾는 정규식
    text = text.replace(regex, daysTable[engDay]); // 요일 교체
  });

  return text;
}

export const getClockName = (txt, typ) => {
  const SkyHanjaTable = {
    갑: "甲",
    을: "乙",
    병: "丙",
    정: "丁",
    무: "戊",
    기: "己",
    경: "庚",
    신: "辛",
    임: "壬",
    계: "癸",
  }

  if (typ === "chn") {
    for (const [key, value] of Object.entries(SkyHanjaTable)) {
      if (value === txt) {
        return key
      }
    }
  } else if (typ === "kor") {
    return SkyHanjaTable[txt] || null
  }
  return null
}

export const getClockNameEarth = (txt, typ) => {
  const SkyHanjaTable = {
    자: "子",
    축: "丑",
    인: "寅",
    묘: "卯",
    진: "辰",
    사: "巳",
    오: "午",
    미: "未",
    신: "申",
    유: "酉",
    술: "戌",
    해: "亥",
  }

  if (typ === "chn") {
    for (const [key, value] of Object.entries(SkyHanjaTable)) {
      if (value === txt) {
        return key
      }
    }
  } else if (typ === "kor") {
    return SkyHanjaTable[txt] || null
  }
  return null
}

export const boardMap = {
  notice: { boardId: 2325, name: "공지사항" },
  event: { boardId: 2480, name: "이벤트" },
  faq: { boardId: 2434, name: "자주묻는질문" },
  essay: { boardId: 2509, name: "낭만 에세이" },
  counseling: { boardId: 2508, name: "명리상담" },
  inquiry: { boardId: 2336, name: "1:1 문의" },
}

export const getBoardName = (key, type) => {
  const board = boardMap[key]
  if (board) {
    return board[type] || ""
  }
  return ""
}

export const highlightText = (text, searchWord) => {
  if (!searchWord || !text.toLowerCase().includes(searchWord.toLowerCase())) {
    return text
  }

  const startIndex = text.toLowerCase().indexOf(searchWord.toLowerCase())
  const endIndex = startIndex + searchWord.length
  const highlightedPart = text.slice(startIndex, endIndex)
  const beforeText = text.slice(0, startIndex)
  const afterText = text.slice(endIndex)
  return `${beforeText}<span class="highlighted">${highlightedPart}</span>${afterText}`
}

export const formatDateHH = (date) => {
  return moment(date).format("YYYY.MM.DD HH:mm")
}

export const formatDateHHDay = (date) => {
  const formattedDate = moment(date).format("YYYY년 MM월 DD일 HH:mm (ddd요일)")
  return replaceDays(formattedDate)
}

export const formatCustomDate = (dateString) => {
  return moment(dateString).format("YYYY.MM.DD")
}

export const formatCustomDateYYYY = (dateString) => {
  return moment(dateString).format("YYYY")
}

export const formatCustomDateMM = (dateString) => {
  return moment(dateString).format("MM")
}

export const formatCustomDateDD = (dateString) => {
  return moment(dateString).format("DD")
}

export const formatCustomDateHH = (dateString) => {
  return moment(dateString).format("HH:mm")
}

export const truncateString = (str, length) => {
  if (typeof str === "string" && str.length > length) {
    return str.slice(0, length)
  }
  return str
}

export const formatOption1Txt = (str) => {
  if (str) {
    return "야자시/조자시,"
  }
  return ""
}

export const formatOption4Txt = (str) => {
  if (str) {
    return "한국시,"
  }
  return ""
}

export const formatOption2Txt = (str) => {
  if (str === "none") {
    return "모름,"
  }
  if (str) {
    return "동지"
  }
  return "입춘"
}

export const reverseData = (data) => {
  return Object.values(data).reverse()
}

export const getStartDateAndEndDate = (month) => {
  const currentDate = new Date()
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - month, 1)
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const formatStartDate = `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(
    -2
  )}-${`0${startDate.getDate()}`.slice(-2)}`
  const formatEndDate = `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(
    -2
  )}-${`0${endDate.getDate()}`.slice(-2)}`

  const rsDate = []
  rsDate.push(formatStartDate)
  rsDate.push(formatEndDate)

  return rsDate
}

export const getDayOfWeek = (year, month, day) => {
  const daysOfWeek = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
  const date = new Date(`${year}-${month}-${day}`)
  const dayOfWeekIndex = date.getDay()
  return daysOfWeek[dayOfWeekIndex]
}

export const getDayOfWeekEng = (data) => {
  const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
  const date = new Date(`${data}`)
  const dayOfWeekIndex = date.getDay()
  return daysOfWeek[dayOfWeekIndex]
}

export const getDayOfWeekOne = (val) => {
  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"]
  const date = new Date(val)
  const dayOfWeekIndex = date.getDay()
  return daysOfWeek[dayOfWeekIndex]
}

export const getTimeTxt = (time) => {
  const timeParts = time.split(":")
  const hour = timeParts[0].padStart(2, "0")
  const minute = timeParts[1].padStart(2, "0")
  return `${hour}시 ${minute}분`
}

export const getTimeTxt2 = (time) => {
  time = String(time)
  return time?.padStart(2, "0")
}

export const getDateTxt = (dateString) => {
  const date = new Date(dateString)
  const formattedDate = `${date.getFullYear()}.${`0${date.getMonth() + 1}`.slice(
    -2
  )}.${`0${date.getDate()}`.slice(-2)}`
  return formattedDate
}

export const formatTxtLunarString = (dateString) => {
  if (typeof dateString !== "undefined" && dateString && dateString.trim() !== "") {
    const dateStringArry = dateString.split("-")
    const month = parseInt(dateStringArry[1], 10)
    const day = parseInt(dateStringArry[2], 10)
    const formattedDate = `${month}/${day}`
    return formattedDate
  }
  return ""
}

export const getDateTxtString = (dateString) => {
  if (typeof dateString !== "undefined") {
    const dateStringArry = dateString.split("-")
    const year = dateStringArry[0]
    const month = dateStringArry[1]
    const day = dateStringArry[2]
    const formattedDate = `${year}.${String(month).padStart(2, "0")}.${String(day).padStart(
      2,
      "0"
    )}`
    return formattedDate
  }
  return ""
}

export const getDateTxtString_2 = (dateString) => {
  if (typeof dateString !== "undefined") {
    const dateStringArry = dateString.split("-")
    const year = dateStringArry[0].slice(-2)
    const month = dateStringArry[1]
    const day = dateStringArry[2]
    const formattedDate = `${year}/${String(month).padStart(2, "0")}/${String(day).padStart(
      2,
      "0"
    )}`
    return formattedDate
  }
  return ""
}

export const getUserNameTxt = (str) => {
  return str === "" ? "-" : str
}

export const getGroupTxt = (str) => {
  let rsTxt = ""
  if (str === null) {
    rsTxt = "미지정"
  } else {
    rsTxt = str?.name
  }
  return rsTxt
}

export const getGenderTxt = (txt) => {
  let rsTxt = ""
  if (txt === "WOMEN") {
    rsTxt = "여"
  }
  if (txt === "MEN") {
    rsTxt = "남"
  }
  return rsTxt
}

export const getGenderTxt2 = (txt) => {
  let rsTxt = ""
  if (txt === 0 || txt === "0") {
    rsTxt = "여"
  }
  if (txt === 1 || txt === "1") {
    rsTxt = "남"
  }
  return rsTxt
}

export const getGenderTxt3 = (txt) => {
  let rsTxt = ""
  if (txt === 0 || txt === "0") {
    rsTxt = "WOMEN"
  }
  if (txt === 1 || txt === "1") {
    rsTxt = "MEN"
  }
  return rsTxt
}

export const getBirthTimeTxt = (str) => {
  return str === "25:00" ? "--:--" : str
}

export const handleNextInput = (value, nextInputRef, limit) => {
  if (value.length === limit) {
    nextInputRef.focus()
  }
}

export const convertHap = (args, isSky) => {
  const hap = args
  if (!hap) return []
  const result = []

  let _hap = []
  let _hyung = []
  let _chung = []
  let _pa = []
  let _hae = []

  if (!isSky) {
    if (!hap.sky) return []

    _hap = _.chunk([...hap.sky.hap], 2)
    _chung = _.chunk([...hap.sky.chung], 2)
  } else {
    _hap = _.chunk([...hap.earth.hap], 2)
    _hyung = _.chunk([...hap.earth.hyung], 2)
    _chung = _.chunk([...hap.earth.chung], 2)
    _pa = _.chunk([...hap.earth.pa], 2)
    _hae = _.chunk([...hap.earth.hae], 2)
  }

  for (const hapItem of _hap) {
    const [item1, item2] = hapItem
    result.push(`${item1.value}${item2.value}합`)
  }
  for (const hyungItem of _hyung) {
    const [item1, item2] = hyungItem
    result.push(`${item1.value}${item2.value}형`)
  }
  for (const chungItem of _chung) {
    const [item1, item2] = chungItem
    result.push(`${item1.value}${item2.value}충`)
  }
  for (const paItem of _pa) {
    const [item1, item2] = paItem
    result.push(`${item1.value}${item2.value}파`)
  }
  for (const haeItem of _hae) {
    const [item1, item2] = haeItem
    result.push(`${item1.value}${item2.value}해`)
  }

  return result
}

export const convertHapOld = (args) => {
  const result = []

  const earthHap = [...args.earth.hap]
  const earthHyung = [...args.earth.hyung]
  const earthChung = [...args.earth.chung]
  const earthPa = [...args.earth.pa]
  const earthHae = [...args.earth.hae]

  let skyHap = []
  let skyChung = []

  if (args.sky) {
    skyHap = [...args.sky.hap]
    skyChung = [...args.sky.chung]
  }

  const _hap = _.chunk([...skyHap, ...earthHap], 2)
  const _hyung = _.chunk([...earthHyung], 2)
  const _chung = _.chunk([...skyChung, ...earthChung], 2)
  const _pa = _.chunk([...earthPa], 2)
  const _hae = _.chunk([...earthHae], 2)

  for (const hapItem of _hap) {
    const [item1, item2] = hapItem
    result.push(`${item1.value}${item2.value}합`)
  }
  for (const hyungItem of _hyung) {
    const [item1, item2] = hyungItem
    result.push(`${item1.value}${item2.value}형`)
  }
  for (const chungItem of _chung) {
    const [item1, item2] = chungItem
    result.push(`${item1.value}${item2.value}충`)
  }
  for (const paItem of _pa) {
    const [item1, item2] = paItem
    result.push(`${item1.value}${item2.value}파`)
  }
  for (const haeItem of _hae) {
    const [item1, item2] = haeItem
    result.push(`${item1.value}${item2.value}해`)
  }

  return result
}

export const convertGongmangTaewol = (args) => {
  const { gongmang, taewon } = args
  let value = ""
  let gongmangs = [...args.gongmang]
  let taewons = [...args.taewon]

  if (!gongmang || !taewon) return ""

  gongmangs = gongmang.map((item) => item.value)
  taewons = taewon.map((item) => item.value).join("")

  value = `${gongmangs.join("ㆍ")} / ${taewons}`

  return value
}

export const getFirstWord = (text) => {
  return text ? text.split(" ")[1] : ""
}

export const calcAge = (no) => {
  return no + 1
}

export const calculateAge = () => {
  if (this.birthDate) {
    const today = moment()
    const birth = moment(this.birthDate)
    const years = today.diff(birth, "years")
    this.age = years
  }
}

export const getAge = (birthdate) => {
  const today = moment()
  const birth = moment(birthdate)
  const years = today.diff(birth, "years")
  return years
}

export const getYear = (birthdate) => {
  const dateStringArry = birthdate.split("-")
  return dateStringArry[0]
}

export const convertShareTxt = (data) => {
  const { inputdata, transdata, birthganji, twelve, jijanggan, daeuns, gongmang } = data
  let shareTxt = "[낭만만세력]사주결과 공유"

  if (inputdata && transdata) {
    shareTxt += this.convertUserBasicInfo(inputdata, transdata)
    shareTxt += this.convertBirthInfo(inputdata, transdata)
    shareTxt += "\n"
    shareTxt += this.convertUserSelectInfo(inputdata)
  }
  if (birthganji) {
    shareTxt += this.convertGanjiInfo(birthganji)
    shareTxt += this.convertGanjiKorInfo(birthganji)
  }
  if (twelve) {
    shareTxt += this.convertTwelveInfo(twelve)
  }
  if (birthganji) {
    shareTxt += this.convertSinsalInfo(birthganji)
  }
  if (jijanggan) {
    shareTxt += this.convertJijangganInfo(jijanggan)
  }
  if (gongmang) {
    shareTxt += this.convertGongmangInfo(gongmang)
  }
  if (daeuns) {
    shareTxt += this.convertDaeunInfo(daeuns)
  }
  return shareTxt
}

export const convertUserBasicInfo = (inputdata, transdata) => {
  let resultTxt = ""
  const { name } = inputdata
  const gender = inputdata.gender === "1" ? "남" : "여"
  const { age } = transdata

  resultTxt += "\n\n"
  resultTxt += `${name}(${gender} ${age}세) `

  return resultTxt
}

export const convertBirthInfo = (inputdata, transdata) => {
  const { lunsol, hour, min } = inputdata
  const { lunar, solar } = transdata
  let birthDate = ""
  let birthTime = ""
  let birthType = ""

  if (hour === 25) {
    birthTime = "25:00"
  } else {
    birthTime = `${hour < 10 ? "0" : ""}${hour}:${min < 10 ? "0" : ""}${min}`
  }
  if (lunsol === "음력") {
    birthDate = lunar
    birthType = "음"
  } else {
    birthDate = solar
    birthType = "양"
  }

  return `${birthDate}(${birthType}) ${birthTime}`
}

export const convertUserSelectInfo = (inputdata) => {
  const { koreantime, dongji, earlytime } = inputdata
  const printDatas = []

  if (koreantime) printDatas.push("한국시")
  else printDatas.push("동경시")

  if (earlytime) printDatas.push("야자시/조자시")

  if (dongji) printDatas.push("동지")
  else printDatas.push("입춘")

  return printDatas.join("/")
}

export const convertGanjiInfo = (birthganji) => {
  const { sky, earth } = birthganji
  let resultTxt = "\n"
  resultTxt += "\n시 일 월 년"
  resultTxt += `\n${sky.hour.value} ${sky.day.value} ${sky.month.value} ${sky.year.value}`
  resultTxt += `\n${earth.hour.value} ${earth.day.value} ${earth.month.value} ${earth.year.value}`

  return resultTxt
}

export const convertGanjiKorInfo = (birthganji) => {
  const { sky, earth } = birthganji
  let resultTxt = "\n\n십성"
  resultTxt += `\n${sky.hour.six} ${sky.day.six} ${sky.month.six} ${sky.year.six}`
  resultTxt += `\n${earth.hour.six} ${earth.day.six} ${earth.month.six} ${earth.year.six}`

  return resultTxt
}

export const convertTwelveInfo = (twelve) => {
  const { hour, day, month, year } = twelve
  let resultTxt = "\n\n십이운성"
  resultTxt += `\n${hour ?? ""} ${day ?? ""} ${month ?? ""} ${year ?? ""}`

  return resultTxt
}

export const convertSinsalInfo = (birthganji) => {
  let resultTxt = "\n\n십이신살\n"
  const { hour, day, month, year } = birthganji.earth
  if (hour.sal && hour.sal.length > 0) {
    resultTxt += `${hour.sal[0]} `
  } else resultTxt += "  "

  if (day.sal && day.sal.length > 0) {
    resultTxt += `${day.sal[0]} `
  } else resultTxt += "  "

  if (month.sal && month.sal.length > 0) {
    resultTxt += `${month.sal[0]} `
  } else resultTxt += "  "

  if (year.sal && year.sal.length > 0) {
    resultTxt += `${year.sal[0]} `
  } else resultTxt += "  "

  return resultTxt
}

export const convertJijangganInfo = (jijanggan) => {
  const { hour, day, month, year } = jijanggan
  let resultTxt = "\n\n지장간"
  const maxLength = Math.max(hour.jiji.length, day.jiji.length, month.jiji.length, year.jiji.length)

  for (let i = 0; i < maxLength; i += 1) {
    if (i === 0) {
      resultTxt += `\n${hour.jiji[i] ?? "--"} ${day.jiji[i] ?? "--"} ${month.jiji[i] ?? "--"} ${
        year.jiji[i] ?? "--"
      }`
    } else {
      resultTxt += `\n${hour.jiji[i] ?? "--"} ${day.jiji[i] ?? "--"} ${month.jiji[i] ?? "--"} ${
        year.jiji[i] ?? "--"
      }`
    }
  }

  return resultTxt
}

export const convertGongmangInfo = (gongmang) => {
  let resultTxt = "\n\n공망\n"

  const convertData = {}
  for (const item of gongmang) {
    const { pos } = item
    convertData[pos] = item
  }

  const { hour, day, month, year } = convertData

  if (hour) resultTxt += `${hour.value}`
  if (day) resultTxt += `${day.value}`
  if (month) resultTxt += `${month.value}`
  if (year) resultTxt += `${year.value}`

  return resultTxt
}

export const convertDaeunInfo = (daeuns) => {
  const { sky, earth } = daeuns
  let resultTxt = "\n\n대운"
  resultTxt += "\n"

  for (let i = sky.length - 1; i >= 0; i -= 1) {
    resultTxt += `${sky[i].num} `
  }
  resultTxt += "\n"
  for (let i = sky.length - 1; i >= 0; i -= 1) {
    resultTxt += `${sky[i].value} `
  }
  resultTxt += "\n"
  for (let i = earth.length - 1; i >= 0; i -= 1) {
    resultTxt += `${earth[i].value} `
  }

  return resultTxt
}

export const fiveColorCnt = (curDaeun, curBirthFive) => {
  console.log("curBirthFive ---- ")
  console.log(curBirthFive)

  const fiveCnt = JSON.parse(JSON.stringify(curBirthFive))

  const fiveMap = {
    木: "five1",
    목: "five1",
    火: "five2",
    화: "five2",
    土: "five3",
    토: "five3",
    金: "five4",
    금: "five4",
    水: "five5",
    수: "five5",
  }

  const incrementNum = (element) => {
    const key = element?.five
    if (key && fiveMap[key]) {
      fiveCnt[fiveMap[key]].num += 1
    }
  }

  incrementNum(curDaeun?.sky[0])
  incrementNum(curDaeun?.earth[0])

  return fiveCnt
}

export const checkDateTimeValid = (args) => {
  const { checkType } = args

  if (checkType === "date") {
    const { inputYear, inputMonth, inputDay } = args
    const _inputYear = parseInt(inputYear, 10)
    const _inputMonth = parseInt(inputMonth, 10)
    const _inputDay = parseInt(inputDay, 10)

    if (_inputYear < 1900 || _inputYear > 2100) return false
    if (_inputMonth < 1 || _inputMonth > 12) return false

    const monthMaxDate = dayjs()
      .set("year", _inputYear)
      .set("month", _inputMonth - 1)
      .set("date", 1)
      .endOf("month")
      .get("date")

    if (_inputDay < 1 || _inputDay > monthMaxDate) return false
    return true
  }
  const { inputHour, inputMinute } = args
  const _inputHour = parseInt(inputHour, 10)
  const _inputMinute = parseInt(inputMinute, 10)
  if (_inputHour < 0 || _inputHour >= 24) return false
  if (_inputMinute < 0 || _inputMinute >= 60) return false
  return true
}
