import axios from "axios"
import { jwtDecode } from "jwt-decode"
import { getToken, refreshToken, getUid } from "@/utils/auth"

export function setupInterceptors() {
  const refresh = async (config) => {
    const access_token = getToken()
    if (access_token && jwtDecode(access_token).exp > Date.now() / 1000) {
      return axios.request(config)
    }
    try {
      await refreshToken()
      return axios.request(config)
    } catch (error) {
      handleErrorResponse(error)
      return Promise.reject(error)
    }
  }

  axios.interceptors.request.use(
    (config) => {
      const token = getToken()
      const uid = getUid()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
        // 추가적인 헤더 설정이 필요하면 여기에 추가
        config.headers.authUserId = uid
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        console.log(error.response.data)
        if (error.response.data.error_description?.startsWith("Access token expired")) {
          return refresh(error.config)
        }
      }
      handleErrorResponse(error)
      return Promise.reject(error)
    }
  )

  function handleErrorResponse(error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error_description.startsWith("Invalid refresh token (expired)")
    ) {
      store.dispatch("auth/logout") // 로그아웃 액션
      alert("로그인 세션이 종료되었습니다. 다시 로그인해주세요.")
    }
    // 추가적인 에러 처리 로직
  }
}

export default setupInterceptors
