<script setup>
import { VForm } from "vuetify/components/VForm";
import { useStore } from "vuex";

definePage({ meta: { layout: "login" } });

const refVForm = ref();
const store = useStore();
const route = useRoute();
const router = useRouter();

const credentials = ref({
  loginId: "",
  loginPw: "",
});

const errors = ref({
  loginId: undefined,
  loginPw: undefined,
});

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) login();
  });
};
const login = async () => {
  const result = await store.dispatch("user/login", {
    loginId: credentials.value.loginId,
    loginPw: credentials.value.loginPw,
  });
  if (store.state.user.errorMsg) {
    alert(store.state.user.errorMsg);
    return;
  }
  await nextTick(() =>
    router.replace(route.query.to ? String(route.query.to) : "/manse")
  );
};

const isSignInDisabled = computed(() => {
  return (
    credentials.value.loginId.length < 3 || credentials.value.loginPw.length < 3
  );
});
</script>

<template>
  <div class="login-content">
    <RouterLink to="/">
      <div class="login-logo" />
    </RouterLink>

    <VRow no-gutters class="auth-wrapper">
      <VCol
        cols="12"
        class="auth-card-v2 d-flex align-center justify-center"
        style="background-color: rgb(var(--v-theme-surface))"
      >
        <VCard flat :max-width="500" class="mt-12 mt-sm-0 pa-0">
          <VCardText class="login-inner-box">
            <div class="">
              {{ memberName }}
            </div>
            <div class="login-tit-box">만세력과 함께 여정을 떠나볼까요✨</div>
            <div class="join-txt-box">
              처음이신가요?
              <span class="line span-btn" @click="join()">회원가입 하기</span>
            </div>
          </VCardText>
          <VCardText>
            <VForm ref="refVForm" @submit.prevent="onSubmit">
              <VRow>
                <!-- email -->
                <VCol cols="12">
                  <VTextField
                    v-model="credentials.loginId"
                    autofocus
                    label="아이디" 
                    type="text"
                    :rules="[requiredValidator]"
                    :error-messages="errors.loginId"
                  />
                </VCol>

                <!-- password -->
                <VCol cols="12">
                  <VTextField
                    v-model="credentials.loginPw"
                    label="비밀번호"
                    placeholder="············"
                    :rules="[requiredValidator]"
                    :type="isSignInDisabled ? 'text' : 'password'"
                    :error-messages="errors.password"
                    :append-inner-icon="
                      isSignInDisabled ? 'ri-eye-off-line' : 'ri-eye-line'
                    "
                    @click:append-inner="isSignInDisabled = !isPasswordVisible"
                  />

                  <div
                    class="d-flex align-center flex-wrap justify-space-between my-5 gap-2"
                  >
                    <VCheckbox label="로그인 상태 유지" />
                    <!-- <RouterLink
                        class="text-primary"
                        :to="{ name: 'pages-authentication-forgot-password-v2' }"
                      >
                        Forgot Password?
                      </RouterLink> -->
                  </div>
                  <VBtn
                    block
                    :disabled="isSignInDisabled"
                    type="submit"
                    class="h-12"
                  >
                    Login
                  </VBtn>
                </VCol>

                <!-- auth providers -->
                <VCol cols="12" class="text-center">
                  <div class="find-box">
                    <button class="btn-nor">아이디 찾기</button>
                    <button class="btn-nor">비밀번호 재설정</button>
                  </div>
                  <div class="btn-sns-box">
                    <button class="icon-kko btn-rd">카카오</button>
                    <button class="icon-naver btn-rd">네이버</button>
                    <button class="icon-google btn-rd">구글</button>
                    <button class="icon-apple btn-rd">애플</button>
                  </div>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
@use "@nstyles/auth.scss";
</style>
