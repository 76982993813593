<script setup>
import fiveMixin from '@/composables/fiveMixin';
const emit = defineEmits();

const { ...five } = fiveMixin();

const props = defineProps({
  prBirthData: {
    type: Object,
    default: null,
  },
  prLangMode: {
    type: String,
    default: '',
  },
  prType: {
    type: String,
    default: '',
  },
  prCurNum: {
    type: Number,
    default: 0,
  },
});

const schDaeun = (typ, item) => {
  emit('daeunclick', typ, item, props.prBirthData);
};
</script>
<template>
  <div class="data-0102-box">
    <ul
      v-for="(item, index) in prBirthData.sky"
      :key="index"
      class="birth-item-box"
      :class="{ 'birth-item-box': true, 'active': prCurNum === item.num }"
      @click="schDaeun(prType, item)"
    >
      <!--|| currentYear === item.year-->
      <li>
        <span class="no">{{ item.num }}  </span>
      </li>
      <li v-if="item.year">
        <span class="no-year">{{ item.year }}</span>
      </li>
      <li><span class="txts">{{ item.six }}</span></li>
      <li>
        <span
          class="txt-round"
          :class="[five.getColorFive(item.value), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(item.value, 'sky') }} </em><em class="txt-main">{{ item.value }}</em>

        </span>
      </li>
      <li>
        <span
          class="txt-round"
          :class="[five.getColorFive(prBirthData.earth[index].value), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
        >

          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(prBirthData.earth[index].value, 'earth') }} </em>
          <em class="txt-main">{{ prBirthData.earth[index].value }}  </em>
        </span>
      </li>
      <li><span class="txts">{{ prBirthData.earth[index].six }}</span></li>
      <li><span class="txts txt-earth-02">{{ prBirthData.earth[index].twelve }}</span></li>
      <li><span class="txts txt-earth-02">{{ prBirthData.earth[index].sal[0] }}</span></li>
    </ul>
  </div>
</template>